html {
    scroll-behavior: smooth;
  }
  /* Track */
::-webkit-scrollbar-track {
  background-color: #8084a3a1;
}
  /* width */
::-webkit-scrollbar {
  width: 10px;
  height: 7px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(84.05deg, #FAD96A 0%, #FD5FCC 100%); 
  border-radius: 4px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(#7a0fff 0%, #46bdf4 100%);
}
/* .cross-icon {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0px 10px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
} */
.calender-bg input {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 8px 10px 5px;
  border-radius: 6px;
  margin: 0 10px 10px 0;
}