.required:after {
    content:" *";
    color: rgb(125, 97, 175);
  }
  .badges {
    display: flex;
    color: white;
    margin-left: 30px;
  }  
  .Image-sec{
    width: 999px;
    height: 600px;
    border-radius: 20px
  }