@font-face {
  font-family: 'outfit';
  src: url('../fonts/outfit-regular.woff2') format('woff2'),
    url('../fonts/outfit-regular.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'outfit';
  src: url('../fonts/outfit-bold-webfont.woff2') format('woff2'),
    url('../fonts/outfit-bold-webfont.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'outfit';
  src: url('../fonts/outfit-light.woff2') format('woff2'),
    url('../fonts/outfit-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('../fonts/outfit-semibold.woff2') format('woff2'),
    url('../fonts/outfit-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('../fonts/Outfit-Black.woff2') format('woff2'),
    url('../fonts/Outfit-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('../fonts/Outfit-Thin.woff2') format('woff2'),
    url('../fonts/Outfit-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('../fonts/Outfit-ExtraBold.woff2') format('woff2'),
    url('../fonts/Outfit-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

body {
  font-family: 'outfit' !important;
  background: #050D59 !important;
}
.progress-bar p{
  font-size:14px !important;
}
.date-color{
  color:#8dc7ea;
}
.main-tag:hover .price-tag{
  transform: translateY(0px);
}
.purple{color:#9c99c0;}
.md-show{display:none;}
.md-none{display:block;}
.text-inherit{text-transform:inherit;}
.mutopad-w-100{width:calc(100% - 300px);}
.faq-spin{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.text-shadow{
  background: linear-gradient(266.83deg, #ff57e2, #f3cb31);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #1f1d3d;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.is-sticky::after{
  content: "";
  display: block;
  text-align: center;
  margin: auto;
  max-width: 1700px;
  border-bottom: 4px solid #a949bfd9;
  border-radius:0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  bottom: -1px;
  position: absolute;
  left:3%;
  right:3%;
}
input:focus-visible{
  outline: none;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled){
  outline: none;
}
.pink-text{
  color:#f3cb31;
}
/* admin side css */
.sidebar {
  width: 300px;
  padding: 50px 0;
  background:#1e1866;
  box-shadow: 0px 0px 50px #171c250d;
  border-radius: 8px;
  margin-right:15px;
  left: 37px;
  top: 0;
  bottom: 30px;
  max-height:100%;
  transition: all ease-in-out 0.5s;
}
.sidebar .admin-none {
  display: none;
}
.sidebar .main-nav {
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  height: 100%;
  max-height: calc(100% - 100px);
  color: #fff;
}
.sidebar .main-nav li:hover {
  background: linear-gradient(266.83deg, #ff57e2, #f3cb31);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sidebar .main-nav li:hover span {
  color: #f3cb31;
}
.sidebar .main-nav li {
  position: relative;
  padding: 12px 10px 12px 20px;
}
.sidebar .main-nav a {
  color: #fff;
}
.sidebar .main-nav li span {
  font-size: 15px;
}
.admin-side-wraper .content {
  width: 100%;
  float: right;
}
.dark-theme {
  border: none !important;
  padding: 0px 20px 20px 20px;
  border-radius: 11px;
  height: 100%;
}
.dashboard-cards .shadow-cards .widget-metric_6{
  background-image: linear-gradient(84.05deg, #FAD96A 0%, #FD5FCC 100%);
}
.widget-metric_6 .value {
  margin-right: 5px;
  font-size: 28px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.widget-metric_6 .title {
  display: block;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  text-align: center;
}
.widget-metric_6 .icon-wrapper {
  border-radius: 8px;
  -moz-border-radius: 8px;
  display: block;
  position: relative;
  width: 52px;
  height: 52px;
  float: left;
  text-align: center;
  overflow: hidden;
  background: transparent;
}
.widget-metric_6 .icon-wrapper i {
  font-size: 30px;
  line-height: 50px;
  color: #dedefd !important;
}
.widget-metric_6 {
  box-shadow: 0 3px 9px 0 rgb(28 28 51 / 15%);
  width: 100%;
  border-radius: 5px;
  padding: 25px 15px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.admin-side-wraper .card {
  background: #1e2966;
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
}
.admin-side-wraper .manage-card-table {
  max-height: 100vh !important;
  overflow-y: auto;
  height: auto !important;
}
.admin-side-wraper {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 150px;
  padding: 10px 40px;
}
.titles-sett.card-header {
  color: #fff;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
}
.titles-sett .light-blue-btn {
  margin: 0;
}
.del-icon:hover {
  color: #fff;
}
.sidebar .main-nav a:before {
  content: '';
  width: 0;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(266.83deg, #ff57e2, #f3cb31);
  transition: all ease-in-out 0.3s;
}

.sidebar .main-nav a:hover:before,
.sidebar .main-nav li.active a:before {
  width: 4px;
}
  /* right arrow */
.sidebar .main-nav li.active {
  background: #8dc7ea24;
}
.sidebar .main-nav li.active::after {
  content: '';
  position: absolute;
  bottom:0;
  right: -18px;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #8dc7ea;
  border-left: 0px solid transparent;
  border-top: 19px solid transparent;
}
.sidebar .main-nav li.active a {
  background: linear-gradient(266.83deg, #ff57e2, #f3cb31);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sidebar .main-nav li.active span {
  color: #f3cb31;
}
/* manage pools */
.manage-faqs-accordian .accordion-item{
  background:#1e2966;
}
.manage-faqs-accordian  .accordion-button, .manage-faqs-accordian .accordion-header{
  background:#3b4067;
}
.manage-faqs-accordian  .accordion-body{
  background: #1e2966;
}
.manage-qstns {
  display: flex;
  justify-content:space-between;
  align-items: center;
}
.manage-faqs-content {
  width: calc(100% - 320px);
}
.manage-faqs-accordian .faqs-admn-styling.card-header-pills{
  display:flex;
  justify-content: flex-end;
}
.accordion-button:focus{box-shadow:none;}
.manage-faqs-accordian .accordion-header  {
  border-radius: 10px;
}
.manage-faqs-accordian .accordion-header .card-header:hover {padding-left:25px;}
.manage-faqs-accordian .accordion-header .card-header {
  border-bottom-style: dashed;
  border-color: #4ab1f5;
  transition:all ease-in-out .3s;
}
.manage-faqs-accordian .accordion-header .card-header {
  padding-bottom: 20px;
}
.manage-faqs-accordian .accordion-button::after{display:none;}
.manage-faqs-accordian .light-blue-btn {
  background-image: linear-gradient(to right, #7a0fff 0%, #46bdf4 100%);
}
.manage-faqs-accordian .light-blue-btn:hover {
  background-image: linear-gradient(to right, #46bdf4 0%, #2b56f5 100%) !important;
}
.manage-faqs-accordian .qstn-span {
  background-color: #fd7bb6;
  border-radius: 50%;
  height:45px;
  width:45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.manage-pools .table {
  background: #3b4067;
  border: grey;
}
.manage-pools .table > thead {
  vertical-align: bottom;
  background-color: #1f1d3d;
}
.manage-pools .table > tbody > tr > td {
  border-top: 1px solid #9999997a !important;
  padding: 10px 10px;
  font-size: 15px;
  color: #fff;
  line-height: 2;
  vertical-align: middle;
  white-space:nowrap;
}
.table-responsive.manage-pools::-webkit-scrollbar-thumb {
  background: #46bdf4;
  border-radius: 10px;
}
.table-responsive.manage-pools::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}
.table-responsive.manage-pools::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.manage-pool thead,
th {
  font-size: 18px;
  color: #fff;
  line-height: 2;
}
.table > :not(:first-child) {
  border-top: 2px solid transparent;
}
.manage-pools .table {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
}
/* cms pages css */
.ck.ck-button.ck-disabled .ck-button__icon, .ck.ck-button.ck-disabled .ck-button__label, a.ck.ck-button.ck-disabled .ck-button__icon, a.ck.ck-button.ck-disabled .ck-button__label{opacity:9 !important;}
 .ido-ckeditor.setting-ckeditor .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
  background: #121b5f;
}
.ido-ckeditor .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
  height: 180px;
  background-color: #3b4067;
  border-color: transparent;
  color: #fff;
}
.ido-ckeditor .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  background: linear-gradient(to right, #46bdf4 0%, #2b56f5 100%);
}
.ido-ckeditor .ck-rounded-corners .ck.ck-button {
  color:#fff;
}
.ido-ckeditor .ck-rounded-corners .ck.ck-button:hover , .ido-ckeditor .ck.ck-button.ck-on, .ido-ckeditor a.ck.ck-button.ck-on, .ido-ckeditor .ck.ck-button.ck-on:not(.ck-disabled):hover{
  color:#fff;
  background: #5ea4c5;
}
.ido-ckeditor .ck.ck-dropdown.ck-heading-dropdown .ck-dropdown__panel .ck-list__item > button{
  color: #000;
}
.cms-pages-style label {
  color: #fff;
  text-align: left;
  display: flex;
  font-size:25px;
  padding: 10px;
}
.cms-pages-style2 label {
  font-size:20px;
  display: flex;
}
.ido-ckeditor p {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
.ck.ck-dropdown.ck-heading-dropdown .ck-dropdown__button .ck-button__label {
  width: 8em;
  font-size: 18px;
}
.ido-ckeditor .ck-rounded-corners .ck.ck-button {
  font-size: 18px;
}
.cms-pages-style .form-control:focus {
  box-shadow: none;
  border-color: #82cff3 !important;
}
.progress .progress-bar {
  background: rgb(245, 102, 150);
}
.cms-pages-style .form-control {
  border: none;
  background: #050d5982;
  color: #fff;
  font-size: 17px;
  padding: 10px 15px;
  border-radius: 8px;
  height: 55px;
  outline: none;
  box-shadow: none;
}
.create-pool .form-control::placeholder, .cms-pages-style .form-control::placeholder, .form-control::placeholder {
  color: #ccc !important;
}
.cms-pages-style textarea.form-control {
  min-height: calc(7.5em + 0.75rem + 2px);
  resize: none !important;
}
.faqs-pg .faqs-admn-styling p {
  margin: 0;
}
.faqs-pg .faqs-admn-styling li {
  margin-right: 10px;
  cursor: pointer;
}
.faqs-pg .faqs-admn-styling li.yellow-box,
.faqs-pg .faqs-admn-styling li.green-box {
  border-radius: 8px;
}
.faqs-pg .faqs-admn-styling p.card-text {
  font-size: 16px;
  font-weight: 300;
}
/* end */
.custom-block {
  max-width: 1700px;
  margin: 0 auto;
}
a {
  text-decoration: none !important;
}
section {
  padding-top: 120px;
}
.section_padding {
  padding-top: 150px;
}
h2 {
  font-size: 42px;
  font-weight: 900;
  line-height: 48px;
}
.h2 {
  font-size: 60px;
  font-weight: 900;
  line-height: 60px;
}
h3 {
  font-size: 30px;
  font-weight: 800;
  line-height: 42px;
  font-weight: 700;
}
p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}
.blue {
  color: #8dc7ea;
}
.purple {
  color: #959be0;
}
.pink {
  color: #fd5fcc;
}
.theme-btn {
  background-color: #0a0a2d;
  padding: 18px 25px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  box-shadow: none;
  border: none;
  transition: all ease-in-out 0.3s;
  position: relative;
  overflow: hidden;
}
.theme-btn span {
  position: relative;
  z-index: 100;
}
.theme-btn::before, .theme-btn::after {
  content: '';
  position: absolute;
  height: 50%;
  background-color: #fff;
  right: 0;
  top: 0;
  width: 0px;
  transition: all ease-in-out 0.3s;
}
.theme-btn::after {
  left: 0;
  bottom: 0;
  top: unset;
}
.theme-btn:hover::after, .theme-btn:hover::before {
  width: 100%;
}
.theme-btn:hover span {
  color: #0a0a2d;
}
.shadow-btn {
  background-image: linear-gradient(84.05deg, #FAD96A 0%, #FD5FCC 100%);
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  padding: 20px 34px;
  border-radius: 8px;
  cursor: pointer;
  transition: all ease-in-out 0.4s;
  font-weight: 700;
  display: block;
  border: none;
}
.shadow-btn:hover {
  color: #fff;
  box-shadow: 0 0 12px -2px;
  background-image: linear-gradient(84.05deg, #FD5FCC 0%, #FAD96A 100%);
}
.project-bg .text-btn-bg span{
  background-image: linear-gradient(84.05deg, #FD5FCC 0%, #FAD96A 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-btn-bg {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px 34px;
}
.text-btn-bg:hover {
  box-shadow: 0 0 12px -2px;
}
.text-shadow-btn {
  background: #000;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  padding: 20px 34px;
  border-radius: 8px;
  cursor: pointer;
  background: linear-gradient(266.83deg, #000, #000);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all ease-in-out 0.4s;
}
.light-blue-btn {
  background-image: linear-gradient(86.3deg, #FDDF7C -46.4%, #A948BF 100%);
  font-size: 20px;
  border: 0;
  line-height: 24px;
  color: #fff;
  padding: 19px 34px;
  border-radius: 8px;
  cursor: pointer;
  transition: all ease-in-out 0.4s;
  font-weight: 700;
  display: block;
}
.light-blue-btn:hover {
  color: #fff;
  box-shadow: 0 0 12px -2px;
  background-image: linear-gradient(86.3deg, #A948BF  -46.4%, #FDDF7C 100%);
}
.light-btn {
  background-color: rgb(71 183 244);
  font-size: 20px;
  border: 0;
  line-height: 24px;
  color: #fff;
  padding: 19px 34px;
  border-radius: 8px;
  cursor: pointer;
  transition: all ease-in-out 0.4s;
  font-weight: 700;
  border: 2px solid transparent;
  border: 1px solid transparent;
}
.light-btn:hover {
  color: #fff;
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
  box-shadow: 0 0 12px -2px;
}
.blue-outline-btn {
  background: transparent;
  border: 1px solid #8dc7ea;
  font-size: 16px;
  line-height: 20px;
  color: #8dc7ea;
  padding: 20px 34px;
  border-radius: 8px;
  cursor: pointer;
  transition: all ease-in-out 0.4s;
  font-weight: 700;
}
.blue-outline-btn:hover {
  color: #8dc7ea;
  background: linear-gradient(
    84.05deg,
    rgb(141 199 234 / 25%) 0%,
    rgb(149 155 224 / 25%) 100%
  );
  box-shadow: 0 0 12px -2px;
}
.block-btn {
  display: inline-flex;
}
.tag-btn {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  padding: 12px 14px;
  color: #fff;
  border-radius: 8px;
  border: 1px solid transparent;
  cursor: pointer;
  background-color: #383663;
  transition: all ease-in-out 0.4s;
  display: inline-block;
}
.tag-btn:hover,
.green-tag-btn:hover {
  box-shadow: 0 0 12px -2px;
}
.green-tag-btn {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  padding: 12px 14px;
  color: #fff;
  border-radius: 8px;
  border: 1px solid transparent;
  cursor: pointer;
  background-color: royalblue;
  transition: all ease-in-out 0.4s;
}
.tag-btn:hover {
  color: #fff;
  background: transparent;
  border: 1px solid #fff;
}
.green-tag-btn:hover {
  color: #fff;
  background: transparent;
  border: 1px solid #fff;
}
.del_btn {
  background: transparent;
  border: none;
  border-radius: 5px;
  padding: 2px 10px;
  color: #fff;
  transition: all ease-in-out 0.4s;
}
.del_btn:hover {
  background: #353456;
  color: #fff;
}
.del_btn:hover svg {
  color: #fff;
}
.block-btn a:not(:last-child) {
  margin-right: 10px;
}
.btn-check:focus+.btn-primary, .btn-primary:focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.border-left{
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.border-right{
  border-bottom-right-radius: 0;
}
.y_space {
  padding-top: 125px;
}
.img-cover {
  text-align: center;
}
.img-cover-box{
  border:none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cross-icon-wrapper {
  position: relative;
  height: 175px;
  width: 260px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
span.cross-icon {
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0;
  border-radius: 50%;
  padding: 0px 8px;
  border: 3px solid #f4967dbd;
  cursor: pointer;
  background-color: #010103;
  color: white;
  font-size: 16px;
}
.logo_text{
  text-transform: capitalize;
  font-size: 22px;
  line-height: 26px;
  margin: 0 auto;
  text-align: center;
  color: #b6b3e4;
}
.upload-img{
  width: 30px;
  animation: upDown 1.2s infinite;
}
.upload-img img {
  width:100%;
  height:100%;
  object-fit:cover;
}
@keyframes upDown{
  0%{
    transform:translateY(0);
  }
  50%{
    transform:translateY(-15px);
  }
  100%{
    transform:translateY(0);
  }
}

  input[type='file'] {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: default;
  
}
.green_icon {
  fill: #000;
}
.claim_text_block {
  color: #fff;
  font-weight: 700;
  padding: 10px;
  background-color: #28254a30;
  border-radius: 10px;
}
.check_text {
  font-weight: 500;
}
.claim_code {
  background-color: rgb(40, 167, 69);
  border: 1px solid rgb(40, 167, 69);
  color: #fff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ----------- */
.claim_text {
  color: #fff;
}
/* HEADER */
header {
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0;
}
header .shadow-btn {
  background-image: linear-gradient(to right, #46bdf4 0%, #2b56f5 100%);
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  padding: 20px 34px;
  border-radius: 8px;
  font-weight: 700;
  border:transparent;
  margin-right: 10px;
}
.navbar-expand-lg .navbar-nav a {
  position: relative;
  color: #fff;
  transition: all ease-in-out 0.3s;
  padding:24px;
  font-size: 18px;
}
header .navbar-light .navbar-nav a,header .navbar-light .navbar-nav a{
    font-weight: bold;
}
header .navbar-light .navbar-nav a{
  transition:all ease-in-out .3s;
}
header .navbar-light .navbar-nav a .fire-icon {
    position: absolute;
    right: 0;
    -webkit-text-fill-color: white;
}
header .navbar-light .navbar-nav a span:hover,header .navbar-light .navbar-nav a.current{
  background: linear-gradient(266.83deg, #ff57e2, #f3cb31);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
header .navbar-light .navbar-nav a:hover::after {
  width: 16px;
}
header .navbar-light .navbar-nav a.linkroll:hover::after {
  position:inherit;
}
header .navbar-light .navbar-nav a.linkroll::after {
  width:0;
  height: 0;
  display: none;
}
header .navbar-light .navbar-nav a::after {
  top: -28px;
  content: '';
  height: 16px;
  left: 0;
  border-radius: 20px;
  right: 0;
  margin: auto;
  position: absolute;
  background: linear-gradient(63.43deg, #FAD96A 0%, #FD5FCC 133.33%);
  transition: all ease-in-out 0.5s;
  width: 0;
}
header .shadow-btn:hover {
  background-image: linear-gradient(86.3deg, #A948BF  -46.4%, #FDDF7C 100%); border:transparent;
}
header .shadow-btn:hover,
header .light-blue-btn:hover {
  box-shadow: 0 0 12px -2px;
}
header .light-blue-btn {
  background-image: linear-gradient(to right, #7a0fff 0%, #46bdf4 100%);
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  padding: 19px 34px;
  border: 0 !important;
  border-radius: 8px;
  font-weight: 700;
}
header .light-blue-btn:hover {
  background-image: linear-gradient(to right, #46bdf4 0%, #2b56f5 100%);
}
header {
  padding: 10px 0px;
}
header .navbar-light .navbar-brand {
  display: inline-block;
}
header .block-btn a:not(:last-child) {
  margin-right: 16px;
}
header .navbar-light .navbar-nav .nav-link {
  position: relative;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: capitalize;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
header .navbar-light .navbar-nav .nav-link:not(:last-child) {
  margin-right: 70px;
}
header .navbar-light .navbar-nav .nav-link.active,
header .navbar-light .navbar-nav .show > .nav-link {
  color: #46bdf4;
}
header .navbar-light .navbar-nav .nav-link:before {
  content: '';
  width: 0;
  height: 8px;
  position: absolute;
  left: 40%;
  background: linear-gradient(266.83deg, #46bdf4, #46bdf4);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  top: -28px;
  transition: all 0.3s ease-in-out;
}
header .navbar-light .navbar-nav .nav-link:hover {
  color: #46bdf4;
}
header .navbar-light .navbar-nav .nav-link.active:before,
header .navbar-light .navbar-nav .nav-link:hover:before {
  width: 12px;
}
/* BANNER */
.hero-banner {
  background-image: url('../images/hero-banner-img.png');
  background-position: top;
  background-size: 100% 100%;
  padding: 300px 0px 50px 0px;
}
.hero-banner .banner-text {
  color: #fff;
  margin: 0 auto;
  text-align: center;
}
.hero-banner .banner-text .banner-span {
  font-size: 80px;
  font-weight: 100;
  line-height: 90px;
}
.hero-banner .banner-text .banner-h1 {
  font-size: 100px;
  font-weight: 700;
  line-height: 90px;
  background: linear-gradient(266.83deg, #ff57e2, #f3cb31);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-wrapper {
  margin-bottom: 60px;
}
.hero-banner .banner-text .light-p {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #9c99c0;
}
.hero-banner .banner-text .p {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}
.hero-banner .banner-text .block-btn a:not(:last-child) {
  margin-right: 30px;
}
.hero-banner .banner-text .clipboard {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1d1a35;
  width: fit-content;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 0 auto;
}
.hero-banner .banner-text .clipboard .block-btn {
  position: relative;
  margin-right: 20px;
  border-right: 1px solid #322e55;
  padding-right: 20px;
}
.hero-banner .banner-text .clipboard .fa-copy {
  margin-left: 40px;
  color: #8dc7ea;
  font-size: 25px;
}
.hero-banner .banner-text .clipboard .block-btn .blue-outline-btn {
  padding: 13px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
}
.block-btn a:not(:last-child) {
  margin-right: 10px;
}
.hero-banner .banner-text .clipboard > .block-btn::after {
  display: inline-block;
  content: '';
  width: 2px;
  height: 100%;
  border-left: 1px solid #322e55;
  position: absolute;
  top: 0;
  right: -20px;
}
/* market Cap */
.market-cap .market-cap-inner {  
  background:linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.6) 100%),  url(./../images/net.svg) no-repeat;
  background-size:cover;
  border-radius: 24px;
  padding:86px;
  margin: 0 100px;
  position: relative;
  margin:0 auto;
}
.market-cap .market-cap-inner ul {
  display: flex;
  list-style: none;
  margin-bottom: 0px;
  padding-left: 0px;
  justify-content: space-between;
}
.market-cap .market-cap-inner ul li {
  color: #fff;
  text-align: center;
}
.qoute-p{
  margin:20px 0px;
  font-size: 16px;
  line-height: 27px;
  font-weight: 300;
}
.price {
  font-size: 48px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 30px;
}
.blue-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
  color: #b6b3e4;
  margin-bottom: 10px;
}
/* FEATURE */
.feature .feature-carousal .slick-slide {
  margin-right: 10px;
}
.feature .feature-h2 h2 {
  background: linear-gradient(266.83deg, #ff57e2, #f3cb31);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 257px;
}
.feature .card {
  border-color: #8dc7ea !important;
  background-color: transparent !important;
}
.card {
  padding: 5px;
  border-color: #8dc7ea;
  background-image: linear-gradient(86.3deg, #0d6dfb -46.4%, #323452 100%);
  border-radius: 8px;
}
.feature .card .feature-img {
  margin-right: 20px;
  width: 80px;
  height: 80px;
  border-radius: 10px;
}
.feature .card .feature-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.feature-price {
  color: #fff;
  margin-bottom: 0px;
  font-size: 22px;
  font-weight: 900;
  line-height: 30px;
}
.feature .slick-arrow.slick-prev {
  display: none !important;
}
/* MULTICHAINCARDS */
.tab-cards .nav-pills {
  justify-content: start;
  /* margin-bottom: 80px; */
  align-items: center;
  padding: 0px 15px;
}
.tab-cards .nav-item:last-child {
  margin-right: 0px;
}
.tab-cards .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: linear-gradient(84.05deg, #FAD96A 0%, #FD60CC 100%);
  border: transparent;
  color: #fff;
  box-shadow: 0 0 12px -2px;
}
.pools-search-wrapper {
  width: 250px;
}
.tab-cards .nav-pills .nav-link {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  background: transparent;
  border: 1px solid #8dc7ea;
  color: #8dc7ea;
  padding: 16px 40px;
  border-radius: 0px;
  cursor: pointer;
  height: 100%;
}
.tab-cards .nav-pills .nav-item:first-child .nav-link {
  border-radius: 8px 0px 0px 8px;
}
.tab-cards .nav-pills .nav-item:last-child .nav-link {
  border-radius: 0px 8px 8px 0px;
}
.tab-cards .nav-pills li:not(:last-child) {
  margin-right: 30px;
}
.tab-cards .card {
  color: #fff;
  padding:25px;
  border-radius:18px;
  box-sizing: border-box;
  background-clip: padding-box;
  border: 3px solid #8dc7ea;
  position: relative;
  transition: all ease-in-out 0.3s;
  height: 100%;
}
.tab-cards .card:hover {
  border: 3px solid #f3ba2f;
  transform: scale(1.03);
}
.tab-cards .card:hover::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -3px;
  border: 0px solid transparent;
  border-radius: inherit;
}
.tab-cards .card .card-title {
  font-size:27px;
  font-weight:600;
  line-height:35px;
}
.tab-cards .card p {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}
.tab-cards .card .game-img {
  margin-right: 20px;
  width: 60px;
  height:60px;
  border-radius: 50%;
  background: linear-gradient(90deg, #6825e7, #aa58b8);
  padding: 4px;
}
.tab-cards .card .game-img.game-img-wrap {
  width: 80px;
  height:80px;
  position: relative;
}
.game-img-wrap-child-img {
  position: absolute;
  width: 35px;
  height: 35px;
  bottom: -12px;
  right: 0;
  background-color: #000000;
  border-radius: 50%;
  padding: 4px;
}
.game-img-wrap-child-img img {
  width: 100%;
  height: 100%;
}
.tab-cards .card .game-img img {
  height: 100%;
  width: 100%;
  border-radius:50%;
}
.pools-card-images-info-wrapper {
  width: calc(100% - 80px);
}
.pools-card-images-info {
  margin-right: 8px;
}
.pools-card-images-info-wrapper .badges {
  margin-left: 0px;
  display: block;
}
.tab-cards .card .social-icon-bar {
  padding:40px 0px;
  transition: all ease-in-out 0.3s;
}
.tab-cards .card .social-icon-bar ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
}
.tab-cards .card .social-icon-bar ul li {
  margin-bottom: 12px;
}
.tab-cards .card .social-icon-bar ul li:not(:last-child) {
  margin-right:25px;
}
.tab-cards .card .social-icon-bar ul li figure {width:35px; height:35px; display: flex; justify-content: center; align-items: center; background: #42aef4; padding: 5px; border-radius:5px;}
.tab-cards .card .social-icon-bar ul li figure img {
  width: 100%;
  object-fit: cover;
}
.tab-cards .card .social-icon-bar ul li figure:hover {
  background-color: #0a0a2d;
}
.tab-cards .card .progress-bar-div {
  color: #6c6e6f;
}
.tab-cards .card .progress-bar-div .bar-result {
  color: #8dc7ea;
}
.tab-cards .card .progress-bar-div .progress {
  background-color: transparent;
  height: 10px;
}
.tab-cards .card .progress-bar-div .progress .progress-bar {
  background-color: #6c6e6f;
  width: 100%;
  border-radius: 59px;
}
.tab-cards .card .card-footer {
  padding: 0px;
  margin: 40px -20px 0px;
  border-top: 2px solid #6c6e6f;
}
.tab-cards .card .card-footer .card-footer-content {
  padding: 20px 20px 0px 20px;
}
.tab-cards .card .card-footer ul {
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
}
.tab-cards .card .card-footer ul li:not(:last-child) {
  margin-right: 10px;
}
.tab-cards .card .card-footer ul li {
  overflow: hidden;
  max-width: 100%;
}
.tab-cards .card .card-footer ul li div,
.tab-cards .card .card-footer ul li div .feature-price.pink,
.tab-cards .card .card-footer ul li div .feature-price.purple,
.tab-cards .card .card-footer ul li div span {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.tab-cards .card .feature-price.blue {
  color: #8dc7ea;
}
.tab-cards .card .feature-price.purple {
  color: #959be0;
}
.tab-cards .card .feature-price.pink {
  color: #fd5fcc;
}
.tab-cards .card .card-footer .layout {
  background-color: #8dc7ea;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 auto;
  position: absolute;
  bottom: -13px;
  left: 20%;
  right: 20%;
}
.tab-cards .card .card-footer .layout span {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
}
.slick-prev,
.slick-next {
  top: calc(50% - 7px) !important;
}
/* APPLY AS A PROJECT */
.your-project .project-bg {
  background: linear-gradient(84.05deg, #FAD96A 0%, #FD5FCC 100%);
  border-radius: 24px;
  padding: 50px 100px;
  color: #fff;
}
.space-p {
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.2em;
}
.width-wrap {
  width: 50%;
}
/* FOOTER */
footer .footer-header {
  padding: 120px 0px 50px 0px;
  color: #fff;
  position: relative;
}
footer .footer-header::before{
  content: "";
  display: block;
  left: 5%;
  border-top: 1px solid #322e55;
  right: 5%;
  position: absolute;
  top: 55px;
}
footer .footer-header .social-icons {
  display: flex;
  padding-left: 0px;
  list-style: none;
}
footer .footer-header .social-icons li:not(:last-child) {
  margin-right: 30px;
}
footer .footer-header .social-icons li a {
  transition: all ease-in-out 0.2s;
  font-size:20px;
  color:#0cbcfc;
}
footer .footer-header .social-icons li a:hover {
  color: #f0b90b;
}
footer .footer-header .footer-mb {
  margin-bottom: 50px;
}
.footer-links {
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
}
.footer-links li a {
  font-size: 18px;
  font-weight: 400;
  line-height: 42px;
  text-decoration: none;
  color: #fff;
  transition: all ease-in-out 0.3s;
}
.footer-links li a:hover {
  background: linear-gradient(266.83deg, #ff57e2, #f3cb31);
  background-clip: border-box;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.footer-links li a img {
  width: 120px;
  margin-bottom: 20px;
}
footer .footer-bottom-inner {
  color: #fff;
  padding: 40px 0px;
  border-top: 1px solid #322e55;
}
footer .footer-bottom .footer-links {
  display: flex;
}
footer .footer-bottom .footer-links li:not(:last-child) {
  margin-right: 50px;
}
/* Product Info */
.counter_value{
  font-size: 50px;
  line-height: 54px;
  color: #46bdf4;
}
#clockdiv{
	font-family: sans-serif;
	color: #fff;
	display: inline-block;
	font-weight: 100;
	text-align: center;
	font-size: 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#clockdiv > div{
  margin-bottom: 2px;
	display: inline-block;
  align-items:center;
  justify-self:center;
}
#clockdiv > div:not(:last-child){
  margin-right: 5px;
}
#clockdiv div:first-child > span{
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
#clockdiv div:last-child > span{
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
#clockdiv div > span{
	padding: 10px 20px;
	background: linear-gradient(to right, #7a0fff 0%, #46bdf4 100%);
  width: 100%;
	display: inline-block;
  display: flex;
  align-items:center;
  justify-self:center;
}
/* SLIDE_CODE_TEXT */
.slide_text{
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 auto;
  margin: 0 auto;
  width: calc(100% - 2.75rem);
  text-align: center;
  position: relative;
  border-radius: 6px;
  box-sizing: border-box;
  color: var(--cp-color-1);
  display: block;
  z-index: 2;
  overflow: hidden;
  padding: 3px;
}
.slide_text::before{
  content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #7a0fff 0%, #46bdf4 100%);
}
.slide_text span{ 
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
  position: relative;
  z-index: 2;
  padding:10px;
  font-size: 1.1rem;
  align-items: center;
  background: #181232;
  border-radius: 3px;
  display: block;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;
  word-break: break-all;
}
.smalltext{
	padding-top: 5px;
	font-size: 16px;
}
.profile-info,
.coin-detail {
  background:#1e2966;
  padding: 16px;
  border-radius: 20px;
}
.profile-info.pools-position {
  position: sticky !important;
  top: 6.75rem;
}
iframe {
  display: none !important;
}
.comment-section p {
  color: #fff;
}
.comment-section .comment .user-pic {
  width: 45px;
  height: 45px;
  display: none;
}
.comment-section .comment:not(:last-child) {
  border-bottom: 1px solid #ffffffb8;
  margin-bottom: 12px;
}
.comment-section .comment p.user-name,
.comment-section .comment .upload-time p {
  color: #ffffffb8;
  font-size: 16px;
}
.comment-section .comment .comment-details p {
  text-align: justify;
  font-size: 17px;
  line-height: 20px;
}
.comment-section {
  background: #1e2966;
  padding: 16px;
  border-radius: 20px;
}
.add-comment input {
  background-color: transparent;
    color: #fff;
}
.add-comment input:focus {
  outline: none;
  box-shadow: none;
  background-color: transparent;
  color: #fff;
}
.coin-detail {
  padding: 25px;
}
.profile-info .profile, .coin-profile-img {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background: linear-gradient(90deg, #6825e7, #aa58b8);
  padding: 4px;
  margin-right: 0.75rem;
  display: inline-block;
}
.profile-info .profile img,
.coin-profile img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.profile-info .top-sec {
  display: flex;
  margin-bottom: 20px;
}
.profile-info .name {
  color: white;
  margin-bottom: 5px;
}
.badges {
  display: flex;
  color: white;
  margin-left: 80px;
  margin-bottom: 30px;
}
.badges .outerside {
  display: block;
  padding: 6px 13px;
  border-radius: 10rem;
  font-size: 17px;
  line-height: 15px;
  font-weight: 600;
  margin-right: 10px;
  letter-spacing: 1px;
}
.badges .close-badge {
  background: #aecaeb;
  color: black;
}
.badges .live-badge {
  background: black;
  color: #fff;
  position: relative;
  padding-left: 20px;
}
.badges .live-badge::before {
  content: '';
  position: absolute;
  background-color: white;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  left: 7px;
  top: 50%;
  transform: translateY(-50%);
}
.badges .upcoming-badge {
  background: #e2755b;
  color: white;
}
.badges .badge-light-green {
  color: #0ea55e;
  background: #114646;
}
.curr-info p {
  text-overflow:ellipsis;
  overflow:hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.pools-sell-details {
  margin-bottom: 8px;
}
.pools-sell-details:last-child {
  margin-bottom: 0;
}
.pools-sell-details h6 {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
}
.curr-info p {
  color: #ffffffeb;
}
.pools-sell-details p {
  font-size: 20px !important;
  color: #8dc7ea;
  font-weight: 600 !important;
}
.badges span {
  background: linear-gradient(86.3deg, #FDDF7C -46.4%, #A948BF 100%);
}
.wallet-sec {
  padding: 0.75rem;
  background: rgb(40 37 74);
  border-radius: 20px;
  margin-bottom: 0.4rem;
}
.wallet-sec .btn {
  background-image: linear-gradient(to right, #7a0fff 0%, #46bdf4 100%);
  border-radius: 8px;
  transition: all ease-in-out 0.4s;
  width: 100%;
  margin: 5px 0;
}
.wallet-sec .btn:hover {
  background-image: linear-gradient(to right, #46bdf4 0%, #2b56f5 100%);
  box-shadow: 0 0 12px -2px;
}
.participate {
  margin-bottom: 30px;
}
.participate a {
  color: rgb(115 115 115);
  border-bottom: 1px dotted rgb(115 115 115);
}
.usd {
  color: #0cbcfc;
  margin-bottom: 20px;
  font-weight: 200;
  font-size: 20px;
}
.usd h3 {
  margin-bottom: auto;
  font-weight: 500;
  font-size: 20px;
}
.usd h4 {
  margin-bottom: auto;
  font-weight: 300;
  font-size: 15px;
}
.prog-text {
  justify-content: space-between;
}
.progress-bar {
  background: none;
}
.progress-bar {
  margin-bottom: 15px;
}
.wifi {
  margin-bottom: 10px;
  text-align: left;
}
.wifi svg {
  margin-right: 10px;
  padding-bottom: 3px;
  fill: #4aaef5;
}
.social-icons {
  margin-bottom: 25px;
}
.social-icons ul {
  list-style: none;
  padding: 0;
  display: flex;
}
.social-icons ul li a {
  font-size: 25px;
  color: #0cbcfc;
  transition: all ease-in-out 0.3s;
}
.social-icons ul li a:hover {
  color: #f0b90b;
  filter: drop-shadow(0px 5px 10px #47b9f4);
}
.social-icons ul li {
  margin-right: 30px;
}
.pool-detail .title {
  font-weight:600;
  margin-right: 3px;
  font-size: 18px;
}
.pool-detail h4 {
  font-weight: 700;
  margin-bottom: 20px;
  background: linear-gradient(266.83deg, #ff57e2, #f3cb31);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pool-detail ul {
  padding: 0;
  list-style: none;
}
.pool-detail ul li {
  margin-bottom: 10px;
  line-height: 20px;
}
.desc-color {
  color: #0cbcfc;
}
.progress .progress-bar {
  height: 100%;
  background: #f7af0f;
}
.input-wrapper {
  display: flex;
  position: relative;
}
.filed-wrapper {
  flex: 1;
  height: 70px;
}
.input-wrapper .light-blue-btn{
  height: 70px;
}
.stack_flex {
  display: flex;
  justify-content: space-between;
}
.filed-wrapper.stack-wrap .input-field {
  height:70px;
}
.filed-wrapper .input-field {
  width: 100%;
  height: 100%;
  background: #1f1d3d;
  border: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 24px;
  line-height: 28px;
  padding-left: 15px;
}
.input-wrapper .light-blue-btn {
  padding: 10px 10px;
  font-size: 24px;
  line-height: 28px;
  border-radius: 10px;
}
/* FAQS */
#faqs .faq-heading h2 {
  color: #fff;
  text-align: center;
  background: linear-gradient(266.83deg, #ff57e2, #f3cb31);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 200px;
  margin: 0 auto;
}
#faqs .accordion-button {
  background-color: transparent;
  color: #fff;
  font-size: 20px;
  padding-left: 0;
  padding-right: 0;
}
#faqs .accordion-body {
  color: #fff;
  font-size: 18px;
  padding-left: 0;
  padding-right: 0;
}
#faqs .accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #fff;
}
#faqs .accordion-button:focus {
  box-shadow: none;
}
#faqs .accordion-button::after {
  display: none;
}
#faqs .accordion-collapse.show {
  background-color: transparent;
}
#faqs .accordion-item {
  background-color: transparent;
  border: 0;
}
#faqs .accordion-item:not(:last-child) {
  border-bottom: 1px solid #fff;
}
header .icon-btn {
  height: 60px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  background: linear-gradient(86.3deg, #FDDF7C -46.4%, #A948BF 100%);
}
header .icon-btn img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}
.page-heading {
  margin-top: 50px;
  margin-bottom: 50px;
}
.page-heading h2 {
  background: linear-gradient(266.83deg, #ff57e2, #f3cb31);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 300px;
  margin: 0 auto;
}
.create-pool {
  margin-top: 50px;
}
.create-pool .form-label {
  font-size: 18px;
  padding-left: 5px;
  font-weight: 500;
}
.create-pool .form-control {
  border: none;
  background: #050d5982;
  color: #fff;
  font-size: 22px;
  padding: 10px 15px;
  border-radius: 8px;
  height: 70px;
  outline: none;
  box-shadow: none;
}
.create-pool .form-control::placeholder {
  color: #808080a3;
}
.form-control[type='file']:not(:disabled):not([readonly]) {
  padding: 20px 25px;
}
.create-pool .btn-wrapper {
  border-top: 1px solid #56556d;
  padding-top: 20px;
  text-align: right;
}
.create-pool input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.pool-modal.auction-modal .modal-content{
  background-color: #383663;
}
.pool-modal.auction-modal .icon .loader{
  background-image: linear-gradient(90deg,#46bdf4 0,#2b56f5);
  border-radius: 2px;
}
.pool-modal.auction-modal .accordion-button:not(.collapsed)::after, .pool-modal.auction-modal .accordion-button::after{display:none;}
.pool-modal.auction-modal .modal-header{background-image: linear-gradient(90deg,#46bdf4 0,#2b56f5);}
.pool-modal.auction-modal .accordion-item{margin-bottom: 10px;}
.pool-modal.auction-modal .modal-body{background-color:#191a224a;}
.pool-modal.auction-modal .modal-footer{background-color:#191a224a;}
.pool-modal.auction-modal .accordion-body{display:none;}
.pool-modal.auction-modal .accordion-item .accordion-button{
  background: #1f2347;
}
.pool-modal.auction-modal .modal-content {
  background-color: #1f1d3d;
  color: #fff;
}
.pool-modal .modal-content .modal-header {
  border-bottom: 1px solid #56556d;
}
.pool-modal .accordion {
  border-radius: 8px;
}
.pool-modal .accordion-item {
  border-color: #1f1d3d;
  background: #393664;
}
.pool-modal .accordion-item:first-of-type {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.pool-modal .accordion-item:last-of-type {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.pool-modal .accordion-item .accordion-button {
  background: #393664;
  box-shadow: none;
  outline: none;
  border-bottom: 1px solid #1f1d3d;
  color: #fff;
}
.pool-modal .accordion-item .accordion-button.collapsed {
  border: none;
}
.token-color{color:#8dc7ea;}
.pool-modal .modal-header .btn-close{opacity:9;}
.pool-modal .accordion-item .icon {
  width: 35px;
  height: 35px;
  background:rgb(69 186 244);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
}
.icon .loader {
  background: #393664;
}
.icon .lds-ring {
  width: 30px;
  height: 30px;
}
.icon .lds-ring div {
  width: 25px;
  height: 25px;
  border: 4px solid #3a107f;
  border-color: #fff transparent transparent transparent;
}
.pool-modal .accordion-button::after {
  filter: invert(1);
}
.pool-modal .accordion-button:not(.collapsed)::after {
  filter: invert(0);
}
.pool-modal .accordion-item .icon.pending {
  background: #fff;
  color: #000;
}
.pool-modal .accordion-item .content {
  flex: 1;
  text-align: center;
}
.pool-modal .accordion-item .content button {
  width: 100%;
  max-width: 250px;
}
.pool-modal .accordion-item .content h5 {
  font-weight: bold;
}
.pool-modal .accordion-item .content p {
  font-size: 18px;
}
.pool-modal .btn-close {
  filter: invert(1);
}
.pool-modal .btn-wrapper {
  text-align: center;
  margin-bottom: 20px;
}
.pool-modal .btn-wrapper button {
  height: 60px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.page-content {
  color: #ffff;
  background: #19192e;
  padding: 20px;
}
.page-content h3 {
  font-size: 24px;
}
.page-content p {
  font-size: 16px;
}
.loader {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #393664f7;
  left: 0;
  top: 0;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 45px;
  height: 45px;
  margin: 4px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Table css product info */
.products-table.table-responsive.manage-pools {
  margin-top: 50px;
}
.products-table.manage-pools .table > thead {
  background: linear-gradient(90deg, #6825e7, #aa58b8);
}
.products-table.manage-pools tr:nth-child(even) {
  background-color: #181232;
}
.products-table.manage-pools .table {
  padding: 0.75rem;
  background: rgb(40 37 74);
  border-radius: 20px;
  margin-bottom: 0.4rem;
}
.manage-pools tr:nth-child(even) {
  background-color: #181232;
}
.manage-pools.border.border-secondary {
  border-radius: 8px;
}
.manage-pools .table {
  background: #3b4067;
  border: transparent;
}
.manage-pools .table > thead ,.manage-pools .table > thead th{
  vertical-align: bottom;
  background-color: #1f1d3d;
  color: white;
}
.manage-pools td div {
  justify-content: center;
}
.manage-pools td span {
  margin-right: 0px !important;
  background:transparent;
}
.manage-pools .table > tbody > tr > td {
  border-top: none !important;
  font-size: 15px;
  color: #fff;
  line-height: 2;
  padding: 10px 10px 10px 10px;
  text-align: center;
  background: transparent;
}
.manage-pool thead,
th {
  padding: 10px 10px 10px 19px !important;
  font-size: 18px;
  color: #fff;
  line-height: 2;
}
.table > :not(:first-child) {
  border-top: 2px solid transparent;
}
.manage-pools .table {
  border-radius: 10px;
  overflow: unset;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
}
.manage-pools .table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  height: 600px;
}
/* faq modal */
.faq-modal {
  background-color: #383663;
  color: white !important;
}
.faq-modal.modal-header {
  background-image: linear-gradient(to right, #46bdf4 0%, #2b56f5 100%);
  padding: 3px 10px;
}
.blue-imp.light-blue-btn {
  background-image: linear-gradient(to right, #46bdf4 0%, #2b56f5 100%);
}
.faq-modal .btn-close {
  fill: #fff;
}
.faq-modal span {
  display: flex;
  position: absolute;
  right: 11px;
  font-size: 45px;
}
.faq-modal #inner-message p span {
  position: inherit;
  font-size: inherit;
}
/* faq-loader */
.faq-loader {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 50vh;
  width: 100%;
}
.faq-modal button.close-btn {
  color: #fff;
  background: transparent;
  border: none;
  font-size: 42px;
  font-weight: 700;
}
.faq-modal .btn-close {
  background: none;
  display: none;
}
/* Alert css */
#message {
  position: fixed;
  top: auto;
  background: linear-gradient(to right, #7a0fff 0%, #46bdf4 100%);
  left: 48%;
  color: #fff;
  width: 49.5%;
  z-index: 999;
  overflow-y: auto;
}
#hidden-message {
  position: fixed;
  top: 90vh;
  display: none;
}
#inner-message h2 {
  font-size: 55px;
  font-weight: 600;
}
#inner-message .content {
  margin: 0;
  font-size: 20px;
}
#inner-message {
  margin: 0 auto;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}
#inner-message p{
  height: inherit;
  padding: 10px;
  display: flex;
  align-items: center;
  height:70px;
}
#inner-message p span{
  display: block;
  padding: 10px 0px;
  height:inherit;
}
#inner-message .content {
  width: 95%;
}
.close-alert {
  float: inline-end;
  text-align: end;
  color: black;
  font-weight: 600;
  font-size: 20px;
}
.hidden {
  display: none;
}
.network-drop {
  padding: 10px;
}
.network-drop:hover {
  background-color: #2b56f5;
  color: #322e55;
}
/* flat picker input  */
.flatpickr-input {
  border: none;
  background:#050d5982;
  color: #fff;
  font-size: 22px;
  padding: 10px 15px;
  border-radius: 8px;
  height: 70px;
  outline: none;
  box-shadow: none;
}

::-webkit-calendar-picker-indicator { 
  color: #fff;
  filter: invert(1);
 }
/* Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
}
.switch-input {
  display: none;
}
.switch-label {
  display: block;
  width: 5%;
  height: 30px;
  text-indent: -150%;
  clip: rect(0 0 0 0);
  color: transparent;
  user-select: none;
}
.switch-label::before,
.switch-label::after {
  content: '';
  display: block;
  position: absolute;
  cursor: pointer;
}
.switch-label::before {
  width: 100%;
  height: 100%;
  background-color: #dedede;
  border-radius: 9999em;
  -webkit-transition: background-color 0.25s ease;
  transition: background-color 0.25s ease;
}
.switch-label::after {
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
  -webkit-transition: left 0.25s ease;
  transition: left 0.25s ease;
}
.switch-label:hover::after{
  box-shadow: rgb(17 17 26 / 5%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
}
.switch-input:checked + .switch-label::before {
  background: linear-gradient(to left, #46bdf4 0%, #2b56f5 100%);
}
.switch-input:checked + .switch-label::after {
  left: 24px;
}
.modal-success-status {
  display: flex;
  height: 30vh;
  font-size: 1.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* MANAGE POOLS */
.manage-pools {
  padding: 5px;
  margin: 5px;
  overflow: unset;
  margin-bottom: 25px;
  min-height: 350px;
}
.skeleton {
  overflow: hidden;
  height: 30px;
  position: relative;
}

.skeleton:before {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient( 90deg, transparent, hsla(0, 0%, 100%, 0.4), transparent );
  content: '';
  animation: 1.5s shine linear infinite;
}
.profile_skeleton.skeleton {
  height: 50px;
}
.coin_skeleton.skeleton {
  height: 100px;
}
@keyframes shine {
  0% {
    right: 100%;
  }

  50% {
    right: -128px;
  }

  100% {
    right: 100%;
  }
}
.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after{display:none;}
.flatpickr-months {
  background: #1e1866;
}
.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
span.flatpickr-weekday,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-day.selected,
.flatpickr-current-month
.flatpickr-monthDropdown-months
.flatpickr-monthDropdown-month {
  background: #1e1866 !important;
  color: #fff !important;
  border-color: transparent !important;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #fff !important;
}
.flatpickr-calendar:before,
.flatpickr-calendar:after {
  border: none;
}
.modal-dialog {
  max-width: 480px;
  width: 100%;
}
.main_amount_box {
  border-radius: 30px;
}
.amount_box {
  background: linear-gradient(90deg, #6825e7, #aa58b8);
  padding: 20px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.amount_box ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
  color: #fff;
}
.amount_data {
  background-color: #1f1d3d;
  padding: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.amount_data ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
  color: #fff;
}
.input_btn {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}
.input_btn .form-control {
  padding-left: 100px;
}
.input_btn .upload_btn {
  position: absolute;
  text-transform: capitalize;
  left: 0;
  bottom: 0;
  top: 0;
  background: #353456;
  color: #fff;
  border-color: transparent;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}
input[type=file]::file-selector-button {
  border: transparent;
  padding: .3em .4em;
  border-radius: .2em;
  background: #353456;
  color: #fff;
  transition: 1s;
}
input[type=file]::file-selector-button:hover {
  background: #353456 !important;
  color: #000;
  border: transparent;
}
input[type=file]::file-selector-button:hover {
  background-color: #81ecec;
  border: 2px solid #00cec9;
}
/* AMOUNT-BLOCK-CODE */
.amount_block_code {
  background: linear-gradient(90deg, #6825e7, #aa58b8);
  padding: 20px;
  border-radius: 5px;
}
.amount_block_code ul {
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
}
.amount_block_code ul li:not(:last-child) {
  margin-bottom: 15px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.wallet-sec {
  overflow: hidden;
}
.wallet_blnc {
  display: inline-block;
  border-radius: 8px;
  color: #b6b3e4;
  padding: 10px;
  word-break: break-all;
  background: linear-gradient(
    84.05deg,
    rgb(141 199 234 / 25%) 0%,
    rgb(149 155 224 / 25%)
  );
}
.input_error {
  position: relative;
}
.invalid-feedback {
  position: absolute;
  font-size: 15px;
  line-height: 19px;
}

/* SCROLLBAR */
#scroll-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  background: #1d1a35;
  box-shadow: inset 0 0 5px #353456;
  border-radius: 10px;
}

#scroll-1::-webkit-scrollbar {
  width: 10px;
  background: #1d1a35;
  box-shadow: inset 0 0 5px #353456;
  border-radius: 10px;
}

#scroll-1::-webkit-scrollbar-thumb {
  background: #1d1a35;
  box-shadow: inset 0 0 5px #353456;
  border: 2px solid transparent;
  border-radius: 10px;
}
#style-6::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  background: #fff;
  box-shadow: inset 0 0 5px #46bdf4;
  border-radius: 10px;
}

#style-6::-webkit-scrollbar {
  width:5px;
  background: #46bdf4;
  box-shadow: inset 0 0 5px lightgrey;
  border-radius: 10px;
}

#style-6::-webkit-scrollbar-thumb {
  background: #46bdf4;
  box-shadow: inset 0 0 5px #46bdf4;
  border: 2px solid #46bdf4;
  border-radius: 10px;
}
/* STYLE 4 */
#style-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  background: lightgrey;
  box-shadow: inset 0 0 5px lightgrey;
  border-radius: 10px;
}

#style-4::-webkit-scrollbar {
  width: 10px;
  background: #46bdf4;
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
#style-4::-webkit-scrollbar-thumb {
  background: #46bdf4;
  box-shadow: inset 0 0 5px grey;
  border: 2px solid transparent;
  border-radius: 10px;
}
.primary_dropdown {
  border-color: #b4b3c1;
}
.primary_dropdown:hover {
  border-color: #b4b3c1;
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  border-color: #b4b3c1;
}
.network-drop:hover {
  background-color: #353456;
}
.terms-page i{
  color:#f3cb31;
  font-weight: 600;
}
/* TERMS PAGE */
.terms-page {
  color:#fff;
  background:#1e29663d;
  padding: 20px 40px 40px 40px;
  border-radius: 10px;
  margin-bottom: 50px;
}
.terms-page h3 {
  font-size: 24px;
  margin-bottom: 20px;
  line-height: 40px;
  background: linear-gradient(266.83deg, #ff57e2, #f3cb31);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.terms-page .terms-list-style {
  list-style: lower-alpha;
}
.terms-page .roman-list-style{
  list-style-type: upper-roman;
}
.terms-page ul {
  list-style: none;
}
.terms-page ul li:not(:last-child), .terms-page ol li:not(:last-child){
  margin-bottom: 25px;
  font-size: 20px;
}
.terms-page ul > li::before{
  content: "";
  display: inline-block;
  border-radius: 50%;
  height: 15px;
  min-width: 15px;
  margin-right: 10px;
  margin-top: 5px;
  background: linear-gradient(89.31deg, #FAD96A 0%, #FD60CC 100%);
}
.terms-page a{
  color:#4ab0f5;
  border-bottom:2px solid transparent;
  transition: all ease-in-out .3s;
}
.terms-page a:hover{
  color:#4ab0f5;
  border-bottom:2px solid #4ab0f5;
}
.terms-page .chainLogo{
  height: 30px;
}
/* IDO FRONT SIDE FAQS */
.ido-front-side-faqs {
  margin-bottom:20px;
}
.ido-front-side-faqs .card-title {
  font-weight:600;
}
.ido-front-side-faqs .accordion-button:not(.collapsed)::after, .ido-front-side-faqs .accordion-button::after{
  filter: brightness(0) invert(1);
}
.ido-front-side-faqs .accordion-item{
  border: none;
  background: #3b4067;
}
.ido-front-side-faqs .accordion-button{
  background: linear-gradient(90deg,#7a0fff 0,#46bdf4);
  border-color: transparent;
  padding:25px;
}
.ido-front-side-faqs .accordion-body{
  background:#3b4067;
  color:#fff;
}
.ido-front-side-faqs .ido-ul {
  list-style-type: decimal;
}
.ido-front-side-faqs .ido-ul li:not(:last-child) {
  margin-bottom: 10px;
}
.pool-table thead, th {
  padding: 6px !important;
  font-size: 16px;
  line-height: 25px;
}
.dashboard-side-drop.dropdown .dropdown-toggle {
  background-color: transparent;
  border: unset;
  width: 100%;
  padding: 12px 10px 12px 20px;
  font-size: 21px;
}
.dashboard-side-drop.dropdown li.active svg path, .dashboard-side-drop.dropdown li:hover svg path {
  fill: #f3cb31;
}
.dashboard-side-drop.dropdown li a {
  font-size: 20px;
}
.dashboard-side-drop.dropdown svg path {
  fill: white;
}
.dashboard-side-drop.dropdown .dropdown-toggle::after {
  position: absolute;
  right: 30px;
}
.dashboard-side-drop.dropdown .dropdown-menu.show {
  display: block;
  background-color: transparent;
  width: 100%;
  position: unset !important;
  transform: unset !important;
  border: unset;
}
.dashboard-side-drop.dropdown .dropdown-menu.show li {
  padding-left: 40px;
}
.block-btn button {
  background: linear-gradient(86.3deg, #FDDF7C -46.4%, #A948BF 100%) !important;
  color: white !important;
}
.block-btn button .ju367vfo {
  color: white !important;
}
.block-btn button .ju367vcf {
  border-color: #A948BF !important;
  background: linear-gradient(86.3deg, #FDDF7C -46.4%, #A948BF 100%) !important;
}
.comments-content-wrapper {
  max-height: 400px;
  overflow-y: auto;
  padding: 0px 18px;
  margin-bottom: 20px;
}
.circle-chart .apexcharts-legend {
  display: none;
}
.coin-profile.coin-profile-text {
  margin-right: 80px;
}
.market-cap-inner {
  text-align: center;
}
[data-rk] ._9pm4ki3 {
  inset: unset !important;
  left: 50% !important;
  bottom: 50% !important;
  transform: translate(-50%, 50%) !important;
  padding: 0 !important;
}
.sidebar-btn-close {
  display: none;
}
.toggle-btn {
  width: 40px;
  height: 40px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  position: absolute;
  top: -10px;
  right: -45px;
  box-shadow: 0 0 10px 2px #5dcbc675 !important;
  z-index: 50;
}
.toggle-btn:hover, .toggle-btn:focus, .toggle-btn:active {
  background-color: #ffffff !important;
}
.toggle-btn svg path {
  fill: black;
}
.toggle-btn {
  display: none;
}
.swal2-container.swal2-center>.swal2-popup {
  background-color: #1e2966;
}
[class^=swal2] {
  color: white !important;
}
[data-rk] .ju367v8a, [data-rk] .ju367vbl, [data-rk] .ju367vz {
  background: #1e2966 !important;
}
[data-rk] .ju367vgo, [data-rk] .ju367vf9, [data-rk] .ju367vgu {
  color: #ffffff !important;
}
[data-rk] .ju367v9u {
  background: #0e76fd !important;
}
[data-rk] .ju367vbf {
  background: #00042e !important;
}
.select-dropdown-wrapper .dropdown-menu.show {
  width: 100%;
}
.whitelist-content {
  padding: 0px 90px;
}
.whitelist-content .whitelist-content-info {
  margin-bottom: 15px;
  padding: 12px;
  border-radius: 4px;
  width: 100%;
  background-color: #80808029;
  border: 4px solid transparent;
}
.whitelist-content .whitelist-content-info:hover {
  border-color: #6c3efc;
}         
.whitelist-content .whitelist-content-info:focus, .whitelist-content .whitelist-content-info:active {
  background-color: #80808029;
}
.whitelist-content .whitelist-content-info h6 {
  margin: 0;
  font-weight: 500;
}
.modal-content-scroll {
  max-height: 320px;
  overflow: auto;
  padding-right: 10px;
}
.notification-modal-content {
  padding: 10px;
  border-radius: 7px;
  background-color: #0f14336e;
}
.notification-modal-content:hover {
  background-color: #0f1433b5;
  cursor: pointer;
}
.notification-header button {
  background: linear-gradient(86.3deg, #FDDF7C -46.4%, #A948BF 100%) !important;
  color: white !important;
  border: none;
  box-shadow: none;
}
.notification-header .notification-icon, .notification-header .notification-icon span {
  color: white !important;
}
.notification-modal-content span, .modal-content-scroll span {
  color: white;
}
.notification-modal-content .modal-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.notification-modal-content .modal-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.link-model-content{
  color: #FDDF7C;
  white-space: nowrap;
  cursor: pointer;
}
.link-model-content:hover {
  text-decoration: underline !important;
}
.pools-search.search-tabs {
  margin-left: 20px;
  width: 320px;
}
.pools-search-wrapper {
  width: 100%;
}
.pools-search.search-tabs input {
  border-color: #8dc7ea;
  padding: 19px 40px;
}
.pools-search input{
  background-color: #050d59;
  color: #ffffff9c;
  border-color: #ffffff9c;
}
.pools-search .fa-search{
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #8dc7ea !important;
}
.pools-search input::placeholder{
  color: white;
}
.pools-search input:focus {
  color: #fff;
  background-color: #050d59;
  border-color: #ffffff9c;
  outline: 0;
  box-shadow: unset;
}
.swal2-close {
  margin-top: -18px !important;
}
.rc-pagination-prev button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rc-pagination-next button:after, .rc-pagination-prev button:after {
  font-size: 25px;
  color: black;
  position: relative;
  top: -4px;
}
.no-comments-wrapper {
  height: 100px;
  overflow-y: auto;
  padding: 0px 18px;
}
.no-comments-wrapper p:last-child {
  margin-bottom: 0;
}
.comments-content {
  width: calc(100% - 150px);
}
.comments-content-logo {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}
.comments-content-logo img {
  width: 100%;
  height: 100%;
}
.comments-content textarea {
  border-radius: 8px;
  background-color: #1e2966;
  padding: 15px;
  resize: none;
  box-shadow: unset;
  outline: none;
  color: white;
  width: calc(100% - 45px);
  border-color: #a7d8f3a3;
}
.comments-content textarea::placeholder {
  color: #a7d8f3a3;
}
.active>.page-link, .page-link.active {
  background-color: #7a0fff;
  border-color: white;
  color: white;
}
.disabled>.page-link, .page-link.disabled {
  color: white;
  background-color: transparent;
  border-color: white;
  box-shadow: unset;
}
.page-link {
  background-color: transparent;
  color: white;
}
.page-link:hover {
  color: white;
  background-color: #7a0fff;
}
.pools-box-logo {
  width: 140px;
}
.pools-box-logo img {
  width: 100%;
}
.rc-pagination-prev, .rc-pagination-next, .rc-pagination-item {
  margin-right: 0 !important;
}
.rc-pagination-prev .rc-pagination-item-link, .rc-pagination-next .rc-pagination-item-link, .rc-pagination-item {
  border: 1px solid white !important;
  background-color: transparent !important;
  color: white !important;
}
.rc-pagination-next button:after, .rc-pagination-prev button:after {
  color: white ;
}
.manage-pools-no-data, .manage-faqs-accordian .manage-pools {
  min-height: unset;
}


/* ***************************************************** MEDIA QUERY  ****************************************************** */
@media (min-width: 768px) and (max-width:1200px){
  .mutopad-w-100 {
    width: calc(100% - 200px);
  }
}
@media (max-width: 1799px) {
  .is-sticky::after{
    max-width:1500px;
  }
  .progress {
    height: 10px;
  }
  header .icon-btn {
    height: 48px;
  }
  header .icon-btn img {
    width: 20px;
    height: 20px;
  }
  section {
    padding-top: 80px;
  }
  #productInfo{
    margin-bottom:50px;
  }
  .custom-block {
    max-width: 1500px;
  }
  h2 {
    font-size: 35px;
    line-height: 40px;
  }
  .h2 {
    font-size: 50px;
    line-height: 55px;
  }
  h3 {
    font-size: 25px;
    line-height: 29px;
  }
  header .shadow-btn {
    padding: 15px 34px;
  }
  header .light-blue-btn {
    padding: 14px 34px;
    margin: auto;
  }
  .shadow-btn {
    font-size: 16px;
    line-height: 20px;
    padding: 15px 34px;
    margin: auto;
  }
  .text-btn-bg {
    border-radius: 8px;
  }
  .text-shadow-btn {
    font-size: 16px;
    line-height: 20px;
    padding: 15px 34px;
    margin: auto;
  }
  .light-blue-btn {
    font-size: 16px;
    line-height: 20px;
    padding: 14px 34px;
    margin: auto;
  }
  .blue-outline-btn {
    font-size: 16px;
    line-height: 20px;
    padding: 15px 34px;
    margin: auto;
  }
  .block-btn {
    display: inline-flex;
  }
  .tag-btn {
    font-size: 13px;
    line-height: 14px;
    padding: 10px 14px;
    color: #fff;
    border-radius: 5px;
  }
  .green-tag-btn {
    font-size: 13px;
    line-height: 14px;
    padding: 10px 14px;
    color: #fff;
    border-radius: 5px;
  }
  .block-btn a:not(:last-child) {
    margin-right: 2px;
  }
  .light-btn {
    padding: 10px 25px;
    border-radius: 8px;
  }
  .text-wrapper {
    margin-bottom: 40px;
  }
  /* HEADER */
  header .block-btn a:not(:last-child) {
    margin-right: 10px;
  }
  header .navbar-light .navbar-nav .nav-link:not(:last-child) {
    margin-right: 50px;
  }
  /* BANNER */
  .hero-banner .banner-text .banner-span {
    font-size: 70px;
    line-height: 75px;
  }
  .hero-banner .banner-text .banner-h1 {
    font-size: 120px;
    line-height: 124px;
  }
  .hero-banner .banner-text .light-p {
    font-size: 25px;
  }
  .hero-banner .banner-text .p {
    font-size: 18px;
    line-height: 22px;
  }
  .hero-banner .banner-text .block-btn a:not(:last-child) {
    margin-right: 20px;
  }
  .hero-banner {
    padding: 200px 0px 50px 0px;
}
  /* WAllet */
  .wallet-sec {
    border-radius: 10px;
  }
  /* MARKET */
  .price {
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 15px;
  }
  .blue-title {
    font-size: 16px;
    line-height: 20px;
  }
  .market-cap .market-cap-inner {
    padding:40px;
    margin: 0 80px;
  }
  /* FEATURE */
  .feature-price {
    font-size: 25px;
    line-height: 29px;
  }
  .feature .feature-carousal .card .blue-title {
    margin-bottom: 5px;
  }
  .feature .card .feature-img {
    margin-right: 10px;
    width: 60px;
    height: 60px;
  }
  /* MULTICHAIN TABS */
  .tab-cards .nav-pills .nav-link {
    font-size: 16px;
    line-height: 20px;
    padding: 18px 35px;
  }
  .tab-cards .nav-pills li:not(:last-child) {
    margin-right: 20px;
  }
  .tab-cards .card {
    padding: 30px;
    border-radius: 15px;
  }
  .tab-cards .card .card-title {
    font-size: 22px;
    line-height: 25px;
    display: block;
  }
  .tab-cards .card p {
    font-size: 16px;
    line-height: 20px;
  }
  .tab-cards .card .social-icon-bar ul li a figure {
    width: 20px;
  }
  .tab-cards .card .social-icon-bar ul li a figure img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .tab-cards .card .progress-bar-div .progress {
    height: 5px;
  }
  .tab-cards .card .card-footer {
    margin: 30px -30px 0px;
  }
  .tab-cards .card .card-footer .layout {
    left: 15%;
    right: 15%;
  }
  .tab-cards .card .card-footer .layout span {
    font-size: 16px;
    line-height: 20px;
  }
  /* gradient */
  .your-project .project-bg {
    padding: 50px 100px;
  }
  .space-p {
    font-size: 25px;
    line-height: 29px;
    letter-spacing: 0.1em;
  }
  /* FOOTER */
  footer .footer-header {
    padding: 100px 0px 50px 0px;
  }
  footer .footer-header .footer-mb {
    margin-bottom: 30px;
  }
  .footer-links li a {
    font-size: 16px;
    line-height: 20px;
  }
  .footer-links li:not(:last-child) {
    margin-bottom: 10px;
  }
  footer .footer-bottom-inner {
    padding: 20px 0px;
  }
  footer .footer-header .social-icons li:not(:last-child) {
    margin-right: 20px;
  }
  footer .footer-header .social-icons li a img {
    width: 20px;
  }
  /* message */
  #message {
    width: 38%;
    right: 0;
    bottom: 20px;
    left: auto;
    right: 20px;
    margin-bottom: 5px;
}
header .icon-btn {
  height: 48px;
}
header .icon-btn img {
  width: 20px;
  height: 20px;
}
section {
  padding-top: 80px;
}
.custom-block {
  max-width: 1500px;
}
h2 {
  font-size: 35px;
  line-height: 40px;
}
.h2 {
  font-size: 50px;
  line-height: 55px;
}
h3 {
  font-size: 25px;
  line-height: 29px;
}
header .shadow-btn {
  padding: 15px 34px;
}
header .light-blue-btn {
  padding: 14px 34px;
  margin: auto;
}
.shadow-btn {
  font-size: 16px;
  line-height: 20px;
  padding: 15px 34px;
  margin: auto;
}
.text-btn-bg {
  border-radius: 8px;
}
.text-btn-bg:hover{
  color: #fbbd81;
}
.text-shadow-btn {
  font-size: 16px;
  line-height: 20px;
  padding: 15px 34px;
  margin: auto;
}
.light-blue-btn {
  font-size: 16px;
  line-height: 20px;
  padding: 14px 34px;
  margin: auto;
}
.blue-outline-btn {
  font-size: 16px;
  line-height: 20px;
  padding: 15px 34px;
  margin: auto;
}
.block-btn {
  display: inline-flex;
}
.tag-btn {
  font-size: 13px;
  line-height: 14px;
  padding: 10px 14px;
  color: #fff;
  border-radius: 5px;
}
.green-tag-btn {
  font-size: 13px;
  line-height: 14px;
  padding: 10px 14px;
  color: #fff;
  border-radius: 5px;
}
.block-btn a:not(:last-child) {
  margin-right: 2px;
}
.text-wrapper {
  margin-bottom: 40px;
}
/* HEADER */
header .block-btn a:not(:last-child) {
  margin-right: 10px;
}
header .navbar-light .navbar-nav .nav-link:not(:last-child) {
  margin-right: 50px;
}
/* BANNER */
.hero-banner .banner-text .banner-span {
  font-size: 70px;
  line-height: 75px;
}
.hero-banner .banner-text .banner-h1 {
  font-size: 120px;
  line-height: 124px;
}
.hero-banner .banner-text .light-p {
  font-size: 25px;
}
.hero-banner .banner-text .p {
  font-size: 18px;
  line-height: 22px;
}
.hero-banner .banner-text .block-btn a:not(:last-child) {
  margin-right: 20px;
}
/* MARKET */
.price {
  font-size: 40px;
  line-height: 44px;
  margin-bottom: 15px;
}
.blue-title {
  font-size: 16px;
  line-height: 20px;
}
.market-cap .market-cap-inner {
  padding:30px;
  margin: 0 80px;
}
/* FEATURE */
.feature-price {
  font-size: 25px;
  line-height: 29px;
}
.feature .feature-carousal .card .blue-title {
  margin-bottom: 5px;
}
.feature .card .feature-img {
  margin-right: 10px;
  width: 60px;
  height: 60px;
}
/* MULTICHAIN TABS */
.tab-cards .nav-pills .nav-link {
  font-size: 16px;
  line-height: 20px;
  padding: 18px 35px;
}
.tab-cards .nav-pills li:not(:last-child) {
  margin-right: 20px;
}
.tab-cards .card {
  padding: 20px;
  border-radius: 15px;
}
.tab-cards .card .card-title {
  font-size: 22px;
  line-height: 25px;
  display: block;
}
.tab-cards .card p {
  font-size: 16px;
  line-height: 20px;
}
.tab-cards .card .game-img {
  margin-right: 10px;
  width: 60px;
  height: 60px;
}
.tab-cards .card .social-icon-bar ul li a figure {
  width: 20px;
}
.tab-cards .card .social-icon-bar ul li a figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.tab-cards .card .progress-bar-div .progress {
  height: 5px;
}
.tab-cards .card .card-footer {
  margin: 30px -23px 0px;
}
.tab-cards .card .card-footer .card-footer-content {
  padding: 30px 30px 0px 30px;
}
.tab-cards .card .card-footer .layout {
  left: 15%;
  right: 15%;
}
.tab-cards .card .card-footer .layout span {
  font-size: 16px;
  line-height: 20px;
}
/* gradient */
.your-project .project-bg {
  padding: 50px 100px;
}
.space-p {
  font-size: 25px;
  line-height: 29px;
  letter-spacing: 0.1em;
}
/* FOOTER */
footer .footer-header {
  padding: 100px 0px 50px 0px;
}
footer .footer-header .footer-mb {
  margin-bottom: 30px;
}
.footer-links li a {
  font-size: 16px;
  line-height: 20px;
}
.footer-links li:not(:last-child) {
  margin-bottom: 10px;
}
footer .footer-bottom-inner {
  padding: 20px 0px;
}
footer .footer-header .social-icons li:not(:last-child) {
  margin-right: 20px;
}
footer .footer-header .social-icons li a img {
  width: 20px;
}
/* END OF FOOTER */
header .icon-btn {
  height: 48px;
}
header .icon-btn img {
  width: 20px;
  height: 20px;
}
section {
  padding-top: 80px;
}
.custom-block {
  max-width: 1500px;
}
h2 {
  font-size: 35px;
  line-height: 40px;
}
.h2 {
  font-size: 50px;
  line-height: 55px;
}
h3 {
  font-size: 25px;
  line-height: 29px;
}
.logo_text{
  font-size: 20px;
  line-height: 22px;
}
header .shadow-btn {
  padding: 15px 34px;
}
header .light-blue-btn {
  padding: 14px 34px;
  margin: auto;
}
.shadow-btn {
  font-size: 16px;
  line-height: 20px;
  padding: 15px 34px;
  margin: auto;
}
.text-btn-bg {
  border-radius: 8px;
}
.text-shadow-btn {
  font-size: 16px;
  line-height: 20px;
  padding: 15px 34px;
  margin: auto;
}
.light-blue-btn {
  font-size: 16px;
  line-height: 20px;
  padding: 14px 34px;
  margin: auto;
}
.blue-outline-btn {
  font-size: 16px;
  line-height: 20px;
  padding: 12px 34px;
  margin: auto;
}
.block-btn {
  display: inline-flex;
}
.tag-btn {
  font-size: 13px;
  line-height: 14px;
  padding: 10px 14px;
  color: #fff;
  border-radius: 5px;
}
.green-tag-btn {
  font-size: 13px;
  line-height: 14px;
  padding: 10px 14px;
  color: #fff;
  border-radius: 5px;
}
.block-btn a:not(:last-child) {
  margin-right: 2px;
}
.text-wrapper {
  margin-bottom: 40px;
}
/* HEADER */
header .block-btn a:not(:last-child) {
  margin-right: 10px;
}
header .navbar-light .navbar-nav .nav-link:not(:last-child) {
  margin-right: 50px;
}
/* BANNER */
.hero-banner .banner-text .banner-span {
  font-size: 70px;
  line-height: 75px;
}
.hero-banner .banner-text .banner-h1 {
  font-size: 120px;
  line-height: 124px;
}
.hero-banner .banner-text .light-p {
  font-size: 25px;
}
.hero-banner .banner-text .p {
  font-size: 18px;
  line-height: 22px;
}
.hero-banner .banner-text .block-btn a:not(:last-child) {
  margin-right: 20px;
}
/* MARKET */
.price {
  font-size: 40px;
  line-height: 44px;
  margin-bottom: 15px;
}
.blue-title {
  font-size: 16px;
  line-height: 20px;
}
.market-cap .market-cap-inner {
  padding: 70px 100px;
  margin: 0 80px;
}
/* FEATURE */
.feature-price {
  font-size: 25px;
  line-height: 29px;
}
.feature .feature-carousal .card .blue-title {
  margin-bottom: 5px;
}
.feature .card .feature-img {
  margin-right: 10px;
  width: 60px;
  height: 60px;
}
/* MULTICHAIN TABS */
.tab-cards .nav-pills .nav-link {
  font-size: 16px;
  line-height: 20px;
  padding: 18px 35px;
}
.tab-cards .nav-pills li:not(:last-child) {
  margin-right: 20px;
}
.tab-cards .card {
  padding: 30px;
  border-radius: 15px;
}
.tab-cards .card .card-title {
  font-size: 22px;
  line-height: 25px;
  display: block;
}
.tab-cards .card p {
  font-size: 16px;
  line-height: 20px;
}
.tab-cards .card .game-img {
  margin-right: 10px;
  width: 60px;
  height: 60px;
}
.tab-cards .card .social-icon-bar {
  padding: 10px;
}
.tab-cards .card .social-icon-bar ul li a figure {
  width: 20px;
}
.tab-cards .card .social-icon-bar ul li a figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.tab-cards .card .progress-bar-div .progress {
  height: 5px;
}
.tab-cards .card .card-footer {
  margin: 30px -30px 0px;
}
.tab-cards .card .card-footer .layout {
  left: 15%;
  right: 15%;
}
.tab-cards .card .card-footer .layout span {
  font-size: 16px;
  line-height: 20px;
}
/* gradient */
.your-project .project-bg {
  padding: 50px 100px;
}
.space-p {
  font-size: 25px;
  line-height: 29px;
  letter-spacing: 0.1em;
}
/* FOOTER */
footer .footer-header {
  padding: 100px 0px 50px 0px;
}
footer .footer-header .footer-mb {
  margin-bottom: 30px;
}
.footer-links li a {
  font-size: 16px;
  line-height: 20px;
}
.footer-links li:not(:last-child) {
  margin-bottom: 10px;
}
footer .footer-bottom-inner {
  padding: 20px 0px;
}
footer .footer-header .social-icons li:not(:last-child) {
  margin-right: 20px;
}
footer .footer-header .social-icons li a img {
  width: 20px;
}
.manage-pools {
  overflow-x: auto;
}
.manage-pools table th, .manage-pools table td {
  white-space: nowrap;
}
.pools-sell-details p {
  font-size: 17px !important;
}
.pools-sell-details h6 {
  font-size: 17px;
  margin-right: 6px;
}
.pools-search.search-tabs input {
  padding: 16px 40px;
}
/* END OF FOOTER */
}
@media (max-width: 1650px) {
  .dashboard-chart-wrapper {
    width: 520px;
  }
  .dashboard-chart-wrapper .recharts-wrapper, .dashboard-chart-wrapper .recharts-surface {
    width: 100% !important;
  }
  .recharts-legend-wrapper {
    width: 520px;
  }
}

@media (max-width: 1500px) {
  .custom-block {
    max-width: 1300px;
  }
  footer .footer-header::before {
    left: 2%;
    right: 2%;
}
.pools-sell-details h6 {
  font-size: 16px;
}
.pools-sell-details p {
  font-size: 16px !important;
}
}
@media (max-width: 1300px) {
  .custom-block {
    max-width: 100%;
  }
  .is-sticky::after{
    max-width:100%;
    left:1%;
    right:1%;
  }
}
@media (max-width: 1399px) {
  h2 {
    font-size: 30px;
    line-height: 39px;
  }
  .h2 {
    font-size: 39px;
    line-height: 43px;
  }
  h3 {
    font-size: 20px;
    line-height: 29px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
  }
  .progress {
    height: 6px;
}
  /* TAGLINE */
.slide_text {
    padding: 2px;
}
.slide_text span {
  padding: 5px;
  font-size: 12px;
}
.del_btn {
  padding: 5px 10px;
  font-size: 11px;
}
.light-btn {
  font-size: 16px;
  line-height: 20px;
  padding: 8px 25px;
  border-radius: 5px;
}
.light-btn:hover {
  border: 1px solid #fff;
  transform: scale(1.05);
}
  section {
    padding-top: 60px;
  }
  .section_padding {
    padding-top: 130px;
  }
  .faqs-pg .faqs-admn-styling li.yellow-box,
  .faqs-pg .faqs-admn-styling li.green-box {
    border-radius: 5px;
  }
  .wallet_blnc span{
    font-size:14px;
  }
  .wallet_blnc{
    font-size: 14px;
    padding:5px 9px;
    border-radius:5px;
  }
  .filed-wrapper {
    height:45px;
  }
  .input-wrapper .light-blue-btn{
    height:45px;
  }
  .filed-wrapper .input-field {
    border-top-left-radius:5px;
    border-bottom-left-radius:5px;
    padding-left: 10px;
  }
  .input-wrapper .light-blue-btn {
    border-top-right-radius:5px;
    border-bottom-right-radius:5px;
    font-size:18px;
    line-height:22px;
    padding: 10px 18px;
  }
  /* COUNTER */
  #clockdiv div > span {
    padding: 10px 16px;
    font-size: 24px;
    height:80px;
}
.smalltext {
  padding-top: 2px;
  font-size: 13px;
}
/* HEDAER */
header .navbar-light .navbar-nav .nav-link {
  font-size: 16px;
  line-height: 20px;
}
header .navbar-light .navbar-nav .nav-link:not(:last-child) {
  margin-right: 30px;
}
header {
  padding: 5px 0px;
}
header .navbar-light .navbar-nav .nav-link:before {
  top: -19px;
}

  header .navbar-light .navbar-brand figure {
    width:120px;
  }
  .navbar-expand-lg .navbar-nav a {
    padding:15px 20px;
  }
  header .navbar-light .navbar-nav a::after {
    top: -23px;
  }
  header .icon-btn {
    height: 36px;
    padding: 5px 10px;
  }
  header .icon-btn img {
    width: 20px;
    height: 20px;
  }
  header .light-blue-btn {
    padding: 8px 20px;
    font-size: 14px;
    border-radius: 5px;
    margin: auto;
  }
  .shadow-btn {
    font-size: 16px;
    line-height: 20px;
    padding: 9px 20px;
    font-size: 14px;
    border-radius: 5px;
    margin: auto;
  }
  .text-btn-bg {
    padding: 10px 25px;
    border-radius: 8px;
  }
  .text-shadow-btn {
    font-size: 16px;
    line-height: 20px;
    padding: 9px 20px;
    font-size: 14px;
    border-radius: 5px;
    margin: auto;
  }
  .light-blue-btn {
    font-size: 16px;
    line-height: 20px;
    padding: 9px 20px;
    font-size: 14px;
    border-radius: 5px;
    margin: auto;
  }
  .banner-text .blue-outline-btn {
    padding: 7px 20px;
  }
  .blue-outline-btn {
    font-size: 16px;
    line-height: 20px;
    padding: 9px 20px;
    font-size: 14px;
    border-radius: 5px;
    margin: auto;
  }
  .block-btn {
    display: inline-flex;
  }
  .tag-btn {
    font-size: 13px;
    line-height: 14px;
    padding: 10px 14px;
    border-radius: 5px;
  }
  .green-tag-btn {
    font-size: 13px;
    line-height: 14px;
    padding: 10px 14px;
    border-radius: 5px;
  }
  .input_btn .upload_btn {
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 8px;
  }
  .text-wrapper {
    margin-bottom: 25px;
  }
  .claim_code {
    height: 25px;
    width: 25px;
    font-size: 13px;
  }
  .logo_text{
    font-size: 16px;
    line-height: 20px;
  }
  header .shadow-btn {
    padding: 8px 20px;
    font-size: 14px;
    border-radius: 5px;
  }
  .text-btn-bg {
    border-radius: 8px;
  }
  .blue-outline-btn {
    font-size: 16px;
    line-height: 20px;
    padding: 9px 20px;
    font-size: 14px;
    border-radius: 5px;
    margin: auto;
  }
  /* BANNER */
  .hero-banner {
    padding:160px 0px 50px 0px;
}
  .hero-banner .banner-text .banner-span {
    font-size: 60px;
    line-height: 65px;
  }
  .hero-banner .banner-text .banner-h1 {
    font-size: 100px;
    line-height: 104px;
  }
  .hero-banner .banner-text .light-p {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .hero-banner .banner-text .p {
    font-size: 15px;
    line-height: 19px;
  }
  .hero-banner .banner-text .block-btn a:not(:last-child) {
    margin-right: 10px;
  }
  .hero-banner .banner-text .clipboard {
    padding: 10px 15px;
    border-radius: 8px;
  }
  .hero-banner .banner-text .clipboard .block-btn a:not(:last-child) {
    margin-right: 4px;
  }
  .hero-banner .banner-text .clipboard .block-btn .blue-outline-btn {
    padding: 6px 10px;
    font-size: 10px;
    line-height: 13px;
  }
  .hero-banner .banner-text .clipboard span {
    font-size: 12px;
  }
  .hero-banner .banner-text .clipboard .fa-copy {
    margin-left: 10px;
    font-size: 13px;
  }
  .admin-side-wraper .card {
    height: 88vh;
}
  /* PRICE */
  .price {
    font-size: 27px;
    line-height: 30px;
    margin-bottom: 6px;
  }
  .blue-title {
    font-size: 14px;
    line-height: 17px;
  }
  .market-cap .market-cap-inner {
    padding:20px 50px;
    margin: 0 80px;
    border-radius: 15px;
  }
  /* FEATURE */
  .card {
    padding: 7px;
  }
  .feature .card .feature-img {
    width: 50px;
    height: 50px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 45px;
    height: 45px;
    margin: 4px;
    border: 4px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* flat picker input  */
  .flatpickr-input {
    height: 50px;
    font-size: 16px;
  }
  .filed-wrapper .input-field {
    font-size: 16px;
  }
  /* MANAGE POOLS */
  .manage-pools {
    padding: 10px;
  }
  /* AMOUNT_BLOCK_CODE */
  .amount_block_code {
    border-radius: 15px;
  }
  .amount_block_code ul li:not(:last-child) {
    margin-bottom: 10px;
  }
  /* CREATE_POOL */
  .create-pool {
    margin-top: 25px;
  }
  /* switch */
  .switch{
    width: 40px;
  }
  .switch-label{
    height: 25px;
  }
  .switch-label::after{
    height: 25px;
    width: 25px;
  }
  .switch-input:checked + .switch-label::after {
    left: 14px;
    height: 25px;
    width: 25px;
}
.y_space {
  padding-top: 100px;
}
.feature-price {
  font-size: 15px;
  line-height: 20px;
}
.sidebar {
  width:250px;
}
.admin-side-wraper{margin-top:100px;}
/* PRICE */
.price {
  font-size: 27px;
  line-height: 30px;
  margin-bottom: 6px;
}
.blue-title {
  font-size: 14px;
  line-height: 17px;
}
/* FEATURE */
.card {
  padding: 5px;
}
.progress-bar p{
  font-size:12px !important;
}
.tab-cards .card .social-icon-bar ul li:not(:last-child){margin-right:20px;}
.tab-cards .card .social-icon-bar ul li figure {width:28px; height:28px; border-radius:3px;}
.feature .card .feature-img {
  width: 50px;
  height: 50px;
}
.tab-cards .card .game-img {
  width: 55px;
  height: 45px;
}
.feature-price {
  font-size: 20px;
  line-height: 25px;
}
/* TABS */
.tab-cards .nav-pills li:not(:last-child) {
  margin-right: 15px;
}
.tab-cards .nav-pills .nav-link {
  font-size: 14px;
  line-height: 18px;
  padding: 15px 28px;
}
.tab-cards .card {
  padding:15px 15px 0px 15px;
}
.tab-cards .card .card-title {
  font-size: 18px;
  line-height: 21px;
}
.tab-cards .card p {
  font-size: 14px;
  line-height: 18px;
}
.tag-btn {
  font-size: 10px;
  line-height: 14px;
  padding: 7px 12px;
  margin: auto 0;
}
.green-tag-btn {
  font-size: 10px;
  line-height: 14px;
  padding: 7px 12px;
  margin: auto 0;
}
.tab-cards .card .social-icon-bar {
  margin: 0px;
  padding:20px 0px;
}
.tab-cards .card .card-footer {
  margin:15px -15px 0px;
}
.tab-cards .card .card-footer .card-footer-content {
  padding: 15px 15px 0px 15px;
}
.feature-price {
  font-size: 18px;
  line-height: 21px;
}
.tab-cards .card .card-footer .card-footer-content p,
.tab-cards .card .card-footer .card-footer-content span {
  font-size: 14px;
  line-height: 15px;
  margin-bottom:8px;
}
.tab-cards .card .card-footer .card-footer-content p.ido-bold{
  font-size: 14px;
  line-height:18px;
  margin-bottom:3px;
}
.tab-cards .card .card-footer .layout {
  padding: 1px 10px;
}
/* PROJECT */
.your-project .project-bg {
  padding: 30px 80px;
}
.space-p {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 5px;
}
footer .footer-header::before{
  left:3%;
  right:3%;
}
footer .footer-logo{
  width:120px;
}
.footer-links li a img {
  width: 110px;
  margin-bottom: 10px;
}
footer .footer-header {
  padding:90px 0px 50px 0px;
}
footer .footer-header .footer-mb {
  margin-bottom: 20px;
}
.footer-links li a {
  font-size: 14px;
  line-height: 18px;
}
.footer-links li:not(:last-child) {
  margin-bottom: 7px;
}
footer .footer-bottom-inner {
  padding: 15px 0px;
}
.copyright {
  font-size: 14px;
  line-height: 18px;
}
footer .footer-header .audit-img {
  height: 30px;
}
footer .footer-header .audit-img img {
  height: 100%;
  object-fit: cover;
}
.create-pool .form-control {
  font-size: 15px;
  height: 50px;
}
.cms-pages-style .form-control {
  font-size: 15px;
  border-radius: 5px;
  height: 50px;
}
.sidebar .main-nav li.active a,
.sidebar .main-nav a {
  font-size: 18px;
  line-height: 22px;
}
.widget-metric_6 .value {
  margin-right: 0px;
  font-size: 25px;
}
/* FAQ */
.h4, h4 {
  font-size: 20px;
}
.faq-modal.modal-header button.close-btn {
  font-size: 25px;
}
.faq-modal label.form-label {
  font-size: 16px;
}
#message #inner-message h2 {
  font-size: 35px;
}
#inner-message .content {
  font-size: 16px;
}
#inner-message p{

  padding:5px;
  height:50px;
}
.alert {
  padding: 10px;
}
/* FEATURE */
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 45px;
  height: 45px;
  margin: 4px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.feature-price {
  font-size:16px;
  line-height:20px;
}
/* TABLE */
.manage-pool thead,
th {
  font-size: 16px;
  line-height: 20px;
}
.manage-pools .table > tbody > tr > td {
  font-size: 13px;
  line-height: 17px;
}
.pool-modal .accordion-item .content h5 {
  font-weight: bold;
  font-size: 18px;
}
.pool-modal .accordion-item .content p {
  font-size: 15px;
}
.pool-modal .accordion-item .content button {
  height: 40px;
  font-size: 12px;
}
.pool-modal .btn-wrapper button {
  height: 40px;
}
.form-control[type='file']:not(:disabled):not([readonly]) {
  padding: 13px 20px;
}
.ido-front-side-faqs .card-title, .manage-qstns .card-title {
  font-size:18px;
  line-height:26px;
}
.ido-front-side-faqs .accordion-button {
  padding: 19px;
}
/* TERMS PAGE */
.terms-page h3 {
  font-size: 24px;
  margin-bottom: 20px;
  line-height: 40px;
  background: linear-gradient(266.83deg, #ff57e2, #f3cb31);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.terms-page ul li:not(:last-child), .terms-page ol li:not(:last-child) {
  font-size: 17px;
}
.terms-page ul {
  padding-left: 10px;
}
.terms-page ul > li::before {
  height: 10px;
  min-width: 10px;
}
.terms-page ul li:not(:last-child), .terms-page ol li:not(:last-child){
  margin-bottom: 15px;
}
/* Mnage faqs */
.manage-faqs-accordian .accordion-header .card-header {
  padding-bottom:10px;
}
.manage-faqs-accordian .qstn-span {
  height:35px;
  width:35px;
}
/* CLedotor cms pages */
.cms-pages-style label {
    font-size: 20px;
}
.ido-ckeditor p {
  font-size:16px;
  line-height:25px;
}
.ck.ck-dropdown.ck-heading-dropdown .ck-dropdown__button .ck-button__label {
  font-size:14px;
}
.ido-ckeditor .ck-rounded-corners .ck.ck-button {
  font-size:14px;
}
.pools-sell-details h6 {
  font-size: 15px;
}
.pools-sell-details p {
  font-size: 14px !important;
}
.manage-pools-dd .dropdown-menu.show {
  padding: 6px 0px !important;
}
.usd h3 {
  font-size: 18px;
}
.pools-search.search-tabs input {
  padding: 12px 40px;
}
}
@media (max-width:1199px) and (min-width:991px) {
  .feature-price {
    font-size: 16px;
    line-height: 18px;
}
.tab-cards .card .card-footer .card-footer-content p, .tab-cards .card .card-footer .card-footer-content span {
  font-size: 12px;
}
.tab-cards .card .card-footer .card-footer-content p.ido-bold {
  font-size: 14px;
}
.dashboard-chart-wrapper .recharts-legend-wrapper {
  width: 400px !important;
}
}
@media (max-width: 1199px) {
  section {
    padding-top: 40px;
  }
  /* COUNTER */
  #clockdiv div > span {
    padding: 10px 12px;
    font-size: 20px;
}
  .text-wrapper {
    margin-bottom: 20px;
  }
  .y_space {
    padding-top: 80px;
  }
  /* HEDAER */
  .sidebar {
    top: 100px;
  }
  /* BANNER */
  .hero-banner .banner-text .banner-span {
    font-size: 40px;
    line-height: 45px;
  }
  .hero-banner .banner-text .banner-h1 {
    font-size: 75px;
    line-height: 79px;
  }
  .hero-banner .banner-text .light-p {
    font-size: 18px;
    margin-bottom: 4px;
  }
  .hero-banner .banner-text .p {
    font-size: 13px;
    line-height: 16px;
  }
  /* FEATURE */
  .feature .feature-carousal .slick-slide {
    margin-right: 15px;
  }
  .feature .feature-carousal .slick-next {
    right: -10px;
  }
  .market-cap .market-cap-inner {
    padding: 40px 50px;
    margin: 0 50px;
  }
  .price {
    font-size: 24px;
    line-height: 27px;
  }
  .tab-cards .card .social-icon-bar ul li figure {
    width: 25px;
    height: 25px;
  }
  /* SIDE_BAR */
  .sidebar {
    left: 20px;
    padding: 30px 0px;
  }
  /* TABLE */
  .manage-pool thead,
  th {
    padding: 8px 8px 8px 15px !important;
    font-size: 16px;
  }
  .manage-pools .table > tbody > tr > td {
    font-size: 13px;
  }
  /* WIDGET */
  .widget-metric_6 .value {
    font-size: 14px;
    line-height: 18px;
  }
  .sidebar {
    width: 200px;
  }
  #faqs .accordion-button{
    padding: 10px 0px;
    font-size: 18px;
    line-height: 22px;
  }
  .pools-sell-details h6 {
    font-size: 14px;
}
.navbar-expand-lg .navbar-nav a {
  font-size: 14px;
  padding: 15px;
}
[data-rk] .ju367v9 {
  font-size: 14px !important;
}
header .navbar-light .navbar-brand figure {
  width: 80px;
}
.block-btn button {
  font-size: 14px !important;
}
}
@media (max-width: 991px) {
  /* HEADER */
  .is-sticky{
    background-color: #060e5a;
  }
  header .navbar-light .navbar-nav .nav-link.active,
  header .navbar-light .navbar-nav .show > .nav-link {
    background: linear-gradient(0deg, #46bdf4, #46bdf4);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  header .navbar-light .navbar-nav .nav-link:hover {
    background: linear-gradient(0deg, #46bdf4, #46bdf4);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .navbar-expand-lg .navbar-nav a {
    width: fit-content;
  }
  header .navbar-nav{
    justify-content:center;
    align-items: center;
  }
  header .navbar-light .navbar-nav a::after {
    bottom: 5px;
    height: 8px;
    border-radius:25px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
  header .navbar-light .navbar-brand {
    margin-left: 45px;
  }
  header .navbar-light .navbar-toggler {
    color: #fff;
    border-color: transparent;
    outline: none;
    position: absolute;
    left: 20px;
    padding: 0;
  }
  header .navbar-light .navbar-toggler:focus {
    box-shadow: none;
  }
  header .navbar-collapse {
    border-radius: 10px;
    position: absolute;
    left: 0;
    right: 0;
    top: 75px;
    background: #0a0a2d;
    padding: 5px 20px;
    color: #200d3a;
    border-radius: 5px;
    z-index: 9;
    width: 25%;
    margin: 0 !important;
}
header .navbar-light .navbar-nav a, header .navbar-light .navbar-nav a {
  margin-bottom: 10px;
  margin-bottom: 0;
}
  header .navbar-light .navbar-nav .nav-link::before {
    display: none;
  }
  /* HEADER */
  footer .footer-bottom .footer-links {
    justify-content: end;
  }
  footer .footer-bottom .footer-links li:not(:last-child) {
    margin-right: 20px;
  }
  footer .footer-header {
    text-align: center;
  }
  footer .footer-header .social-icons {
    justify-content: center;
  }
  footer .footer-header p {
    width: 70%;
    margin: 0 auto;
  }
  .feature .feature-h2 h2 {
    max-width: unset;
  }
  #faqs .accordion-button {
    font-size: 18px;
  }
  #faqs .accordion-body {
    font-size: 16px;
  }
  .img-cover-box{
    width: 100%;
  }
  /* MANAGE FAQS */
  .manage-qstns {
    flex-direction: column;
  }
  .admin-side-wraper{padding: 10px;}
  .landing-logos-content-logos {
    margin-bottom: 8px;
  }
  .landing-logos-content-logos:last-child {
    margin-right: 30px !important;
  }
  .chart-div {
    flex-direction: column;
  }
  .sidebar {
    width: 250px;
    position: fixed;
    z-index: 30;
    height: 100vh;
    left: 10px;
}
.mutopad-w-100 {
  width: 100%;
}
.sidebar-btn-close {
  display: flex !important;
}
.sidebar.sidebar-hide {
 left: 10px;
}
.sidebar.close {
  left: -250px;
}
.pools-tabs-wrapper {
  flex-direction: column;
}
.tab-cards .nav-pills {
  margin-bottom: 20px;
}
.sidebar .main-nav li.active::after {
  display: none !important;
}
}
@media (max-width: 767px) {
  p {
    font-size: 14px;
    line-height: 20px;
  }
  .h2 {
    font-size: 33px;
    line-height: 35px;
}
.md-show{display: block;}
.md-none{display: none;}
.copyright{text-align: center;}
  #productInfo{
    margin-bottom:30px;
  }
  .feature-price {
    font-size: 16px;
    line-height: 20px;
}
.img-cover-box{
  height: 150px;
}
.alert {
  padding: 5px;
}
.social-icons ul li a{
  font-size: 20px;
}
span{
  font-size: 16px;
}
.accordion-header  .card-title {
  font-size:16px;
  line-height:20px;
}
.terms-page ul li:not(:last-child), .terms-page ol li:not(:last-child) {
  font-size: 15px;
}
.ido-front-side-faqs .accordion-body {
  font-size: 15px;
  padding: 16px 11px;
}
.terms-page ul li:not(:last-child), .terms-page ol li:not(:last-child) {
  margin-bottom: 10px;
}
#faqs .accordion-body {
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}
  .y_space {
    padding-top: 50px;
  }
  #message #inner-message h2 {
    font-size: 25px;
  }
  .navbar-expand-lg .navbar-nav a {
    font-size: 14px;
    line-height: 19px;
    padding:10px;
  }
  header .icon-btn {
    height: 28px;
  }
  header .icon-btn img {
    width: 15px;
    height: 15px;
  }
  .shadow-btn {
    line-height: 17px;
    padding: 8px 12px;
    font-size: 13px;
  }
  .text-btn-bg {
    border-radius: 8px;
    padding: 7px 15px;
    font-size: 14px;
  }
  .text-btn-bg span{
    font-size: 14px;
  }
  .text-shadow-btn {
    line-height: 17px;
    padding: 8px 12px;
    font-size: 13px;
  }
  .light-blue-btn {
    line-height: 17px;
    padding: 7px 12px;
    font-size: 13px;
  }
  .blue-outline-btn {
    line-height: 17px;
    padding: 7px 12px;
    font-size: 13px;
  }
  .h2 {
    font-size: 26px;
    line-height: 30px;
  }
  .h4,
  h4 {
    font-size: calc(1.275rem + -0.7vw);
  }
  h2 {
    font-size: 28px;
    line-height: 32px;
  }
  h3 {
    font-size: 18px;
    line-height: 22px;
  }
  .text-wrapper {
    margin-bottom: 15px;
  }
  .your-project .project-bg {
    padding: 25px 50px;
    border-radius: 15px;
  }
  .tab-cards .card {
    margin: 0 auto;
  }
  .price {
    font-size: 21px;
    line-height: 24px;
  }
  .market-cap .market-cap-inner {
    padding: 30px 30px;
    margin: 0 30px;
    border-radius: 10px;
  }
  .blue-title {
    font-size: 12px;
    line-height: 17px;
  }
  header .navbar-light .navbar-brand figure {
    width: 80px;
  }
  header .navbar-light .navbar-brand figure img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  header .shadow-btn {
    padding: 5px 15px;
    font-size: 12px;
  }
  header span {
    font-size: 14px;
  }
  header .light-blue-btn {
    padding: 5px 15px;
    font-size: 13px;
  }
  .hero-banner {
    background-size: cover;
  }
  .hero-banner .banner-text .banner-span {
    font-size: 25px;
    line-height: 29px;
  }
  .hero-banner .banner-text .banner-h1 {
    font-size: 41px;
    line-height: 44px;
  }
  .hero-banner .banner-text .light-p {
    font-size: 16px;
    margin-bottom: 3px;
    line-height: 20px;
  }
  .hero-banner .banner-text .p {
    font-size: 11px;
    line-height: 14px;
  }
  .hero-banner .banner-text .clipboard .block-btn .blue-outline-btn {
    padding: 5px 8px;
    font-size: 9px;
    line-height: 12px;
  }
  .hero-banner .banner-text .clipboard .block-btn {
    margin-right: 15px;
  }
  .hero-banner .banner-text .clipboard > .block-btn::after {
    right: -10px;
  }
  .hero-banner .banner-text .clipboard span {
    font-size: 10px;
  }
  .hero-banner .banner-text .clipboard {
    padding: 8px 10px;
    border-radius: 5px;
  }
  .hero-banner {
    padding-top: 130px;
}
  section {
    padding-top: 30px;
  }
  .tab-cards .nav-pills .nav-link {
    font-size: 13px;
    line-height: 16px;
    padding: 10px 15px;
  }
  .tab-cards .nav-pills li:not(:last-child) {
    margin-right: 5px;
  }
  .tab-cards .nav-pills {
    margin-bottom: 35px;
  }
  .tab-cards .card .card-title {
    font-size: 16px;
    line-height: 18px;
  }
  .tab-cards .card p {
    font-size: 11px;
    line-height: 15px;
  }
  .tab-cards .card .game-img {
    width: 60px;
  }
  .space-p {
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 1px;
  }
  footer .footer-header {
    padding:70px 0px 20px 0px;
  }
  .pool-modal .btn-wrapper button {
    height: 50px;
  }
  .pool-modal .accordion-item .icon {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
  .pool-modal .accordion-item .content button {
    height: 50px;
    font-size: 15px;
  }
  .create-pool .form-control {
    font-size: 15px;
    height: 50px;
  }
  .cms-pages-style textarea.form-control {
    min-height: calc(6.5em + -0.25rem + 1px);
  }
  .mutopad-w-100 {
    width: calc(100% - 20px);
  } 
  /* MANAGE_POOLS */
  .titles-sett.card-header {
    font-size: 18px;
    line-height: 22px;
  }
  .manage-pool thead,
  th {
    font-size: 14px;
  }
  .manage-pools .table > tbody > tr > td {
    font-size: 12px;
  }
  /* MANAGE_POOLS */
  .manage-pools {
    padding:0px;
  }
  .manage-pools .card-header {
    padding: 10px;
  }
  .manage-pools .faqs-pg .faqs-admn-styling p {
    margin: 0;
    font-size: 13px;
    padding: 5px 12px;
  }
  /* CMS PAGES */
  .cms-pages-style .form-control {
    height: 50px;
  }
  .cms-pages-style label {
    font-size:18px;
}
  /* DASHBOARD */
  .widget-metric_6 .value {
    margin-right: 0px;
    font-size: 20px;
  }
  .widget-metric_6 .title {
    font-size: 12px;
  }
  .dashboard-cards .bg-primary .right {
    text-align: center;
  }
  /* CREATE_POOL */
  .create-pool .form-label {
    font-size: 16px;
  }
  /* POOLS */
  .tab-cards .nav-item {
    margin-bottom: 5px;
  }
  ::placeholder {
    font-size: 13px;
    color: #ccc;
  }
  :-ms-input-placeholder {
    font-size: 13px;
  }
  ::-ms-input-placeholder {
    font-size: 13px;
  }
  .switch{
    width: 31px;
  }
  .switch-label, .switch-label::after{
    height: 20px;
  }
  .switch-input:checked + .switch-label::after {
    left: 17px;
  } 
  .switch-input:checked + .switch-label::after{
    height: 20px;
    width: 20px;
  }
  .switch-input:checked + .switch-label::before {
    left: 5px;
  }
  .switch-label::after{
    width: 20px;
  }
  footer .footer-header::before{
    top:35px;
    left: 2%;
    right: 2%;
  }
  footer .footer-header .footer-mb img{
    margin-bottom: 10px;
  }
  footer .footer-header .footer-mb {
    margin-bottom: 15px;
  }
  #message {
    right:25px;
  }
  /*  PRODUCT INFO */
  #clockdiv div > span {
    font-size:18px;
    height: auto;
  }
  /* FAQS */
  #faqs .accordion-button {
    font-size: 16px;
  }
  /* MANAGE FAQS */
  .manage-pools .card-header {
    padding: 10px 3px;
  }
  .manage-faqs-accordian .accordion-header .card-header:hover {
    padding-left:3px;
  }
  .manage-faqs-accordian .accordion-button {
    padding:10px;
  }
  .ido-front-side-faqs .card-title, .manage-qstns .card-title {
    font-size: 16px;
  }
  /* CKEDITOR */
  .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners, .ido-ckeditor .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable {
    height:120px;
  }
  /* IDO FAQS */
  .ido-front-side-faqs {
    margin-bottom: 10px;
  }
  /* TERMS OF USE */
  .terms-page {
    padding: 10px 30px 30px 30px;
    border-radius: 8px;
    margin-bottom: 30px;
  }
  .your-project .project-bg {
    padding: 30px 30px;
  }
  .space-p {
    font-size: 15px;
    line-height: 17px;
    margin-bottom: 5px;
  }
  /* IDO FAQS */
  .ido-front-side-faqs .ido-ul li:not(:last-child) {
    margin-bottom: 5px;
    font-size: 15px;
  }
  .manage-qstns .shadow-btn, .manage-qstns .light-blue-btn {
    line-height: 15px;
    padding: 7px 17px;
    font-size: 12px;
  }
  .wallet_blnc span {
    font-size: 13px;
  }
  .market-cap .market-cap-inner{
    background: rgb(0 0 0 / 42%);
  }
  .dashboard-chart-wrapper, .recharts-legend-wrapper {
    width: 100% !important;
}
header .navbar-light .navbar-toggler {
  right: 12px;
  left: unset;
  top: 17px;
}
.header-navbar {
  flex-direction: column;
  align-items: start !important;
}
header .navbar-light .navbar-brand {
  margin-left: 0;
}
.mobile-tabs-handle {
  width: 100%;
  justify-content: center;
  padding-top: 8px;
}
header .navbar-collapse {
  left: unset;
  width: 32%;
  top: 50px;
  right: 12px;
}
[data-rk] ._1ckjpok7 {
  border-radius: 30px !important;
  padding-bottom: 0 !important;
  width: unset !important;
}
[data-rk] ._1ckjpok1 {
  width: 300px !important;
}
.tab-cards .nav-pills {
  margin-bottom: 10px;
}
.admin-side-wraper {
  margin-top: 130px;
}
header .navbar-light .navbar-nav a .fire-icon {
  right: -8px;
}
.coin-profile.coin-profile-text {
  margin-right: 0px;
  margin-bottom: 6px;
}
}
@media (max-width: 576px) {
  .section_padding {
    padding-top: 100px;
  }
  header .navbar-light .navbar-nav a, header .navbar-light .navbar-nav a {
    margin-bottom: 5px;
}
  #message #inner-message h2 {
    font-size: 14px;
  }
  .input_btn .upload_btn {
    font-size: 13px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
  }
  .filed-wrapper , .input-wrapper .light-blue-btn{
    font-size: 20px;
    line-height: 24px;
}
span {
  font-size: 14px;
}
  /* sidebar css */
  .titles-sett.card-header {
    flex-direction: column;
    row-gap: 10px;
  }
  .sm-right-btn {
    width:100%;
    text-align: right;
  }
  .flatpickr-input {
    height: 45px;
}

  /* end of sidebarcss */
  header .navbar {
    padding-top: 0px;
  }
  header .icon-btn {
    height: 30px;
  }
  header .icon-btn img {
    width: 12px;
    height: 12px;
  }
  header .shadow-btn {
    font-size: 13px;
  }
  header .light-blue-btn {
    padding: 5px 12px;

  }
  .your-project .project-bg > div {
    flex-direction: column;
    text-align: center;
  }
  .width-wrap {
    width: auto;
  }
  .market-cap .market-cap-inner ul {
    flex-direction: column;
  }
  .market-cap .market-cap-inner {
    margin: 0 30px;
  }
  .qoute-p{
    font-size:14px;
    line-height:22px;
  }
  .market-cap .market-cap-inner ul li:not(:last-child) {
    margin-bottom: 15px;
  }
  .market-cap .market-cap-inner ul li {
    border-radius: 7px;
    border: 1px solid transparent;
    padding: 10px;
    background:linear-gradient(84.05deg,#fad96a,#fd60cc);
  }
  /* BANNER */
  .hero-banner .banner-text .light-p {
    font-size: 14px;
  }
  .hero-banner .banner-text .banner-span {
    font-size: 20px;
    line-height: 29px;
}
footer .footer-header::before {
  top: 17px;
}
  .footer-logo{
    width:100px;
    margin-left: auto;
    margin-right: auto;
  }
  footer .footer-bottom-inner {
    text-align: center;
  }
  footer .footer-bottom .footer-links {
    justify-content: center;
  }
  footer .footer-header {
    padding:50px 0px 10px 0px;
  }
  .feature .feature-carousal .slick-slide {
    margin: 0 15px;
  }
  .your-project .project-bg {
    padding: 25px 25px;
  }
  .coin-detail {
    padding: 25px 10px;
  }
  .profile-info h2,
  .coin-detail h2 {
    font-size: 22px;
    line-height: 30px;
  }
  .coin-detail p {
    font-size: 14px;
  }
  .profile-info {
    padding: 25px 10px;
    margin-bottom:15px;
  }
  .profile-info .sale {
    margin-bottom: 10px;
  }
  .social-icons ul li {
    margin-right: 24px;
  }
  .pool-detail div {
    width: 100% !important;
  }
  #faqs .accordion-button {
    font-size: 15px;
    padding: 10px 0;
  }
  #faqs .accordion-body {
    font-size: 14px;
  }
  .widget-metric_6 .value {
    font-size: 16px;
  }
  .card-body {
    padding: 5px;
  }
  .cms-pg .card-body {
    padding: 0px;
  }
  .cms-pages-style.cms_terms textarea.form-control {
    white-space: break-spaces;
    overflow-y: scroll;
  }
  .cms-pages-style .form-control {
    height: 35px;
    border-radius: 5px;
  }
  .cms-pages-style label {
    font-size: 13px;
  }
  ::placeholder {
    font-size: 13px;
  }

  :-ms-input-placeholder {
    font-size: 13px;
  }
  ::-ms-input-placeholder {
    font-size: 13px;
  }
  h3 {
    font-size: 18px;
    line-height: 22px;
  }
  .create-pool .form-label {
    font-size: 14px;
  }
  .create-pool .form-control {
    font-size: 13px;
    height: 40px;
  }
  .tab-cards .card .social-icon-bar ul li figure {
    width: 25px;
    height: 25px;
  }
  .tab-cards .card .social-icon-bar ul li figure img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .form-control[type='file']:not(:disabled):not([readonly]) {
    padding: 10px 18px;
  }
  /* flat picker input  */
  .flatpickr-input {
    font-size: 14px;
  }
  /* FAQS */
  .faqs-wraper p {
    font-size: 14px;
  }
  .cms-pages-style .form-control {
    font-size: 14px;
  }
  /* faq */
  .faqs-pg .faqs-admn-styling li {
    margin-right: 5px;
  }
  .faqs-pg .faqs-admn-styling li.yellow-box .nav-link,
  .faqs-pg .faqs-admn-styling li.green-box .nav-link {
    padding: 5px 10px;
    font-size: 13px;
  }
  .faqs-pg .manage-pools h4{
    font-size: 16px;
    line-height: 20px;
  }
  .manage-pools {
    padding: 5px;
}
.text-btn-bg {
  padding: 8px 20px;
  border-radius: 5px;
}
#message {
  right: 25px;
  width: 70%;
  height:fit-content;
}
/* TERMS PAGE */
.terms-page {
  padding: 10px 13px 13px 13px;
  margin-bottom: 20px;
}
.terms-page ul li , .terms-page ol li:not(:last-child){
  margin-bottom: 5px;
}
/* WALLET SECTION */
.wallet-sec {
  padding: 10px;
}
.usd h3 {
  font-size: 18px;
}
.usd h4 {
  font-size: 13px;
}
.amount_block_code span{
  font-size: 16px;
  line-height: 25px;
}
.manage-faqs-accordian .qstn-span {
  height: 25px;
  font-size: 13px;
  width: 25px;
}
/* POOL DETAILS */
.pool-detail .title {
  font-size:17px;
}
.tab-cards .card{
  width:300px;
  border-radius:10px;
}
header {
  padding: 5px 0px;
  box-shadow: 1px 1px 7px 0px #8080806b;
}
header .navbar-collapse {
  width: 60%;
}
.pool-detail ul li {
  word-break: break-all;
}
.hero-banner {
  padding-top: 140px;
}
.block-btn button, [data-rk] .ju367v9 {
  font-size: 12px !important;
}
.section_padding {
  padding-top: 140px;
}
}
@media (max-width: 480px) {
  .h2 {
    font-size: 20px;
    line-height: 29px;
}
  h2 {
    font-size: 23px;
    line-height: 30px;
  }
  .light-blue-btn {
    font-size: 13px;
}
  footer .footer-header p {
    width: auto;
  }
  .feature-price {
    font-size: 16px;
    line-height: 20px;
  }
  .tab-cards .card {
    width: auto;
  }
  .tab-cards .card {
    padding:15px 10px 5px 10px;
  }
  .tab-cards .card .card-footer {
    margin: 15px -11px 0px;
  }
  header .navbar-light .navbar-brand {
    margin-left: 0;
    padding-top: 5px;
    width: 100%;
  }
  header .navbar-light .block-btn {
    margin-left: auto;
    margin-right: auto;
    padding-top:12px;
  }
  .sidebar {top:115px} 
  .hero-banner .block-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .hero-banner .banner-text .block-btn a:not(:last-child) {
    margin-right: auto;
    margin-bottom: 10px;
  }
  .tab-cards .nav-pills li {
    margin-bottom: 10px;
  }
  .clipboard-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  header {
    padding: 5px 0px;
    box-shadow: 1px 1px 7px 0px #8080806b;
}
  .hero-banner .banner-text .clipboard .block-btn {
    margin-right: 15px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
  }
  .hero-banner .banner-text .clipboard .block-btn a {
    margin: 0 0 0 5px;
  }
  .hero-banner .banner-text .clipboard {
    padding: 8px 25px;
  }
  .pool-modal .accordion-item .icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
  .pool-modal .accordion-item .content h5 {
    font-weight: bold;
    font-size: 18px;
  }
  .pool-modal .accordion-item .content p {
    font-size: 15px;
  }
  .pool-modal .accordion-item .content button {
    height: 40px;
    font-size: 12px;
  }
  .pool-modal .btn-wrapper button {
    height: 40px;
  }
  .sidebar .main-nav li {
    position: relative;
    padding: 12px 10px 10px 10px;
  }
  .hero-banner .banner-text .clipboard {
    width: auto;
  }
  /* MANAGE FAQS */
  .manage-qstns {
    flex-direction:column;
  }
  .manage-faqs-accordian .faqs-admn-styling.card-header-pills {
    min-width:auto;
    padding:10px 0px 0px 0px;
    display: flex;
}
.manage-faqs-accordian .accordion-button {
  padding:5px;
}
.space-p {
  font-size: 13px;
  line-height: 14px;
  margin-bottom: 2px;
}
.text-btn-bg {
  padding: 6px 10px;
  border-radius: 5px;
}
.text-btn-bg span{

  font-size:13;
}
/* banner */
.hero-banner {
  padding-top: 180px;
}
.hero-banner .banner-text .banner-h1 {
  font-size: 34px;
  line-height: 40px;
}
.hero-banner .banner-text .banner-span {
  font-size: 17px;
  line-height: 25px;
}
.price {
  font-size: 18px;
  line-height: 21px;
}
.h4, h4 {
  font-size: 15px;
}
.accordion-header button {
  padding: 10px;
}
.your-project .project-bg {
  padding: 18px;
  border-radius: 10px;
}
.faqs-pg .manage-pools h4 {
  font-size: 14px;
  line-height: 18px;
}
.admin-side-wraper {
  margin-top: 140px;
}
.modal-dialog {
  margin: 0;
  padding: 8px;
}
.pools-search.search-tabs {
  margin-left: 0;
  width: 90%;
}
.mobile-tabs-handle {
  display: block !important;
  text-align: center;
}
.admin-side-wraper {
  margin-top: 180px;
}
.toggle-btn {
  top: -56px;
}
.section_padding {
  padding-top: 180px;
}
}
.tbl_pool_name{
  line-height: 13px;
    display: block;
}
.tbl_pool_tokenomics{
  line-height: 18px;
    display: block;
}
.tbl_pool_time{
  line-height: 17px;
  font-size: 13px;
    display: block;
}
.manage-pools-dd .dropdown-toggle::after {
  display: none;
}
tbody tr:first-child .manage-pools-dd .dropdown-menu.show, tbody tr:nth-child(2) .manage-pools-dd .dropdown-menu.show {
  bottom: auto !important;
  top: 0 !important;
}
.manage-pools-dd .dropdown-menu.show {
  margin: 0;
  padding: 0;
  background: #050d59;
  border-radius: 0;
  transform: none !important;
  right: 0 !important;
  inset: unset !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 100;
}
.manage-pools-dd .dropdown-item{
  font-size: 15px;
  padding: 6px 10px;
  color: white;
}
.manage-pools-dd .dropdown-menu {
  border: none;
}
.manage-pools-dd .dropdown-menu::before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #050d59;
  top: -9px;
  right: 6px;
}
.btn-blue, .btn-light-blue {
  background: linear-gradient(to right, #7a0fff 0%, #46bdf4 100%);
  color: white !important;
  border: none;
}
[data-rk] .ju367v9c {
  background: linear-gradient(to right, #7a0fff 0%, #46bdf4 100%) !important;
}
.btn-blue:focus {
  background-color: #2b56f5;
}
.btn-light-blue {
  background-color: #526f7c;
}
.btn-light-blue:focus {
  background-color: #526f7c;
}
.modal-select {
  background-color: transparent;
  color: white;
  border: 1px solid #ffffffc4;
  border-radius: 4px;
  padding: 6px 14px 6px 0;
  box-shadow: unset;
  outline: none;
}
.modal-select option {
  color: black;
}
.not-found-lottie{
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 100px 0 40px;
}