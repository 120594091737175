.cut-text { 
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height:20%;
  }
  /* landing boxes */
  .landing-boxes {
    padding: 40px 0px;
  }
  .landing-boxes-content {
    padding: 25px;
    border-radius: 8px;
    background-color: #0e090f99;
    height: 100%;
}
.landing-boxes-content h3 {
  background: linear-gradient(266.83deg, #ff57e2, #f3cb31);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 30px;
  font-weight: bold;
}
.landing-boxes-content p {
  font-size: 18px;
  color: white;
}
.landing-logos h3 {
  color: white;
  text-transform: uppercase;
}
.landing-logos-content-logos {
  width: 70px;
  background-color: #80808052;
  border-radius: 50%;
  height: 70px;
  padding: 10px;
  margin-right: 30px;
}
.landing-logos-content-logos span {
  position: absolute;
  background-color: #80808052;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 10px;
  white-space: nowrap;
  display: none;
}
.landing-logos-content-logos:last-child {
  margin-right: 0;
}
.landing-logos-content-logos img {
  filter: brightness(0) invert(1);
  opacity: .4;
  height: 100%;
}
.landing-logos-content-logos:hover img {
  opacity: 1;
}
.landing-logos-content-logos:hover span {
  display: block;
}
/* slick carousel */
.slick-carousel {
  padding: 15px 0px 30px;
}
.slick-carousel .slick-track {
  display: flex;
  overflow: hidden;
}
.slick-carousel-heading {
  font-size: 35px;
  color: white;
}
.sr-item {
  padding: 10px 15px;
}
.sr-item-content-wrapper {
  background-color: #2c3265;
  border-radius: 15px;
  padding: 18px;
  border: 1px solid #8084a387;
  min-height: 120px;
}
.sr-item-img {
  width: 60px;
  height: 60px;
  margin-right: 16px;
  border-radius: 6px;
}
.sr-item-img img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.sr-item-content {
  width: calc(100% - 60px);
}
.sr-item-content h5 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0;
  background: linear-gradient(266.83deg, #ff57e2, #f3cb31);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sr-item-content p {
  color: white;
  font-size: 20px;
  margin-bottom: 6px;
  width: 100%;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.slick-carousel-boxes .sr-item a {
  display: inline-block;
}
.slick-carousel-boxes-item {
  margin-right: 25px;
}
.slick-carousel-boxes-item:last-child {
  margin-right: 0px;
}

@media (max-width: 767px) {
  .landing-boxes-content h3 {
    line-height: 32px;
  }
}
@media (max-width: 575px) {
.landing-logos-content-logos {
  width: 50px;
  height: 50px;
  padding: 7px;
}
}