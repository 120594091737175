.wallet-info{
overflow: hidden;
}
.disp{
    display: flex;
    flex-direction: column;
}
/* new css */
.profile-info-scam-text {
    position: absolute;
    background-color: #4e4e4edb;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 20px;
    z-index: 15;
}
.profile-info-scam-text-img {
    max-width: 300px;
    margin: 0 auto;
    background-color: white;
    border-radius: 50%;
}
.product-info-content-wrapper .filed-wrapper .form-control {
    padding: 14px 22px;
    font-size: 20px;
    box-shadow: none;
    border: 1px solid #5530bd;
}
.product-info-content-wrapper .filed-wrapper .form-control::placeholder {
    color: gray !important;
}
.product-info-content-wrapper .light-blue-btn {
    height: unset;
    margin-bottom: 10px !important;
    color: white;
}
.profile-info-scam-text.local-pool {
    padding: 25px 60px;
}
.profile-info-scam-text-content {
    padding: 50px;
    border: 4px solid #d83737f2;
    background: white;
    box-shadow: 1px 1px 11px 5px grey;
}
.profile-info-scam-text-content h5 {
    font-size: 35px;
    color: #d83737f2;
    font-weight: 700;
}
.profile-info-scam-text-content p {
    color: black;
    font-size: 20px;
}
.scamsmall-img {
    width: 80px;
    margin-left: auto;
}
.profile-info-scam-text-content h6 {
    font-weight: bold;
    color: black;
    font-size: 22px;
}
.profile-info-scam-text-content h6 span {
    color: #d83737f2;
}
.kycmessage-box {
    background: #1e2966;
    padding: 25px;
    border-radius: 20px;
}
.profile-info-scam-text.kyc-message {
    position: unset;
}
/* modal */
.product-modal .modal-content {
    background-color: #1e2966;
}
.product-modal .modal-header {
    background-color: #1e2966;
    color: white;
    font-weight: bold;
}
.product-modal form label {
    color: white;
}
.product-info-section {
    z-index: 1;
    width: 100%;
    left: 0;
    background-color: #808080eb;
    padding: 30px;
    border-radius: 12px;
}
.product-info-section .profile-info-scam-text-content {
    background-color: unset;
    border-color: #ffc107;
}
.product-info-section .profile-info-scam-text-content h5, .product-info-section .profile-info-scam-text-content h6 span {
    color: #ffc107;
}
.product-modal .btn-close {
    opacity: 1;
    filter: invert(1);
    box-shadow: unset;
}
.sale-type-wrapper {
    padding: 15px 0px 0px;
}
.sale-type-wrapper .form-check-input[type=radio] {
    cursor: pointer;
}
.sale-type-links a {
    color: white;
    border: 1px solid white;
    width: 100%;
    display: block;
    margin-bottom: 15px;
    text-align: center;
    padding: 10px 0px;
    border-radius: 4px;
}
.sale-type-links a:hover {
    color: #42aef4;
    border-color: #42aef4;
}
.sale-type-wrapper .form-check-input:checked {
    background-color: #42aef4;
    border-color: #42aef4;
    box-shadow: unset;
}
.address-info-content:last-child {
    margin-bottom: 0px;
}
.address-info-content {
    margin-bottom: 8px;
}
.address-info-content p {
    font-size: 15px;
}
.address-info {
    padding: 10px;
    border: 1px solid #ffffff8a;
    border-radius: 4px;
}
.address-cross-icon {
    background: red;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

/* Example Styles for React Tags*/
#app {
  padding: 40px;
}

div.ReactTags__tags {
    position: relative;
}
img.chainLogo.mt-10 {
    height: 46px;
}
/* Styles for the input */
div.ReactTags__tagInput {
    width: 200px;
    border-radius: 2px;
    display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border: 1px solid #eee;
    padding: 0 4px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    cursor: move;
    border-radius: 5px;
    margin-bottom: 10px;
}
div.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}
div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: white;
    width: 200px;
}
div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}
div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}
